import DeleteIcon from '@mui/icons-material/Delete';
import {
  Avatar, Card, IconButton, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import { StyledComponent } from 'src/components/styled-components/styledComponent';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import DynamicHeightComponent from 'src/utils/dynamicSize.component';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import UserListHead from '../../sections/@dashboard/user/UserListHead';
import NoPostFound from '../publishedPosts/noPost.component';

const OrgManageBrand = () => {
  const [brandList, setBrandList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loader, setLoader] = useState(false);
  const { showToast } = useToaster();
  const [toBeDeletedId, setToBeDeletedId] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [height, setHeight] = useState(window.innerHeight - 150);
  const activeBrandId = CommonHelper.GetCurrentBrandId();

  useEffect(() => {
    getAllBrands();
  }, []);

  const getAllBrands = () => {
    setLoader(true);
    let postProps = {
      url: userModuleURL.fetchUsersBrand,
      successCallback,
      failureCallback,
    };
    HttpServices.Get(postProps);
  };
  const successCallback = (data, message) => {
    setBrandList(data);
    message && showToast(message, 's');
    setLoader(false);
  };
  const failureCallback = (message) => {
    message && showToast(message, 'e');
    setLoader(false);
  };
  const handleBrandStatus = (brand) => {
    let bodyProps = {
      brand_id: brand.brand_id,
    };
    let postProps = {
      url: brand.is_enabled ? userModuleURL.disableBrand : userModuleURL.enableBrand,
      body: bodyProps,
      successCallback: successBrandStatusCallback,
      failureCallback: (message) => failureBrandStatusCallback(message, brand.brand_id),
    };
    let updatedBrandList = brandList.map((b) => {
      if (b.brand_id === brand.brand_id) {
        return { ...b, is_enabled: !b.is_enabled };
      }
      return b;
    });

    setBrandList(updatedBrandList);
    HttpServices.Post(postProps);
  };
  const successBrandStatusCallback = (data, message) => {
    showToast(message, 's');
  };
  const failureBrandStatusCallback = (message, brandId) => {
    setBrandList((prevBrandList) =>
      prevBrandList.map((b) => {
        if (b.brand_id === brandId) {
          return { ...b, is_enabled: !b.is_enabled };
        }
        return b;
      })
    );

    showToast(message, 'e');
  };

  const handleDelete = (id) => {
    setToBeDeletedId(id);
    setConfirmOpen(true);
  };

  const confirmDelete = () => {
    setConfirmOpen(false);
    let brandprops = { brand_id: toBeDeletedId };
    let postProps = {
      url: userModuleURL.deleteBrand,
      body: brandprops,
      successCallback: updateSuccessCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };
  const updateSuccessCallback = (data, message) => {
    if (activeBrandId === toBeDeletedId) {
      window.location.reload();
    }
    else {
      getAllBrands();
    }
    showToast(message, 's');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <Helmet>
        <title> Manage Brand </title>
      </Helmet>
      {loader ? (
        <Spinner />
      ) : (
        <>
          {brandList && brandList.length > 0 ? (
            <Card sx={{ m: 1, minHeight: 'calc(100vh - 150px)', maxHeight: 'calc(100vh - 150px)' }}>
              <DynamicHeightComponent>
                <Scrollbar>
                  <TableContainer
                    sx={{
                      maxHeight: height,
                      height: height - 60,
                    }}
                  >
                    <Table stickyHeader>
                      <UserListHead
                        headLabel={[
                          { id: 'profile', label: 'Profile', alignCenter: false },
                          { id: 'brand name', label: 'Brand Name', alignRight: false },
                          { id: 'brand description', label: 'Brand Description', alignRight: false },
                          { id: 'status', label: 'Status', alignCenter: true },
                          { id: 'delete', label: 'Delete', alignCenter: true },
                        ]}
                        rowCount={brandList.length}
                        isShowCheckbox={false}
                      />
                      <TableBody>
                        {brandList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((brand, index) => (
                          <TableRow hover key={brand.brand_id} tabIndex={-1}>
                            <TableCell component="th" scope="row" sx={{ py: 3 }}>
                              <Avatar
                                src={brand.brand_image ? brand.brand_image : brand.brand_name.charAt(0).toUpperCase()}
                                alt={brand.brand_name ? brand.brand_name.charAt(0).toUpperCase() : brand.brand_id}
                                sx={{ height: 40, width: 40, bgcolor: '#00AB55', color: '#ffffff' }}
                              />
                            </TableCell>
                            <TableCell align="left">{brand.brand_name}</TableCell>
                            <TableCell align="left">{brand.description}</TableCell>
                            <TableCell align="center">
                              {' '}
                              <StyledComponent.SwitchIcon
                                sx={{ m: 2 }}
                                checked={brand.is_enabled}
                                onClick={() => {
                                  handleBrandStatus(brand, index);
                                }}
                              ></StyledComponent.SwitchIcon>
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                size="large"
                                onClick={() => {
                                  handleDelete(brand.brand_id);
                                }}
                              >
                                <DeleteIcon color="error" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[10, 15, 25]}
                    count={brandList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                  <ConfirmationDialog
                    open={confirmOpen}
                    onClose={() => setConfirmOpen(false)}
                    onConfirm={confirmDelete}
                    title="Confirm Deletion"
                    message="Are you sure you want to delete this brand?"
                  />
                </Scrollbar>
              </DynamicHeightComponent>
            </Card>
          ) : (
            <Card>
              <NoPostFound height={height} />
            </Card>
          )}
        </>
      )}
    </>
  );
};

export default OrgManageBrand;
