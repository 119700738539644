import { useState } from "react";
import FailedPost from "../failed-posts/failedPost.component";
import ScheduledPost from "../scheduledPost/scheduledPost.component";
import AccountBoxSharpIcon from '@mui/icons-material/AccountBoxSharp';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Helmet } from "react-helmet-async";
import { Box, Tab, Tabs } from "@mui/material";
import { capitalCase } from "change-case";

const PostInfoComponent = () => {
    const [currentTab, setCurrentTab] = useState('Scheduled Posts');
    const ACCOUNT_TABS = [
        {
            value: 'Scheduled Posts',
            icon: <AccountBoxSharpIcon sx={{ width: 25, height: 25, mb:'0 !important' }} />,
            component: <ScheduledPost />
        },
        {
            value: 'Failed Posts',
            icon: <ManageAccountsIcon sx={{ width: 25, height: 25, mb:'0 !important'}} />,
            component: <FailedPost />
        }
    ];
    return (
        <>
            <Helmet>
                <title>Post Info</title>
            </Helmet>
            <Box>
                <Tabs
                    value={currentTab}
                    scrollButtons="auto"
                    variant="scrollable"
                    sx={{  gap:'0.5rem', minHeight:'37px' }}
                    allowScrollButtonsMobile
                    onChange={(e, value) => setCurrentTab(value)}
                >
                    {ACCOUNT_TABS.map((tab) => {
                        return <Tab sx={{flexDirection:'row', gap:'0.25rem', padding:0, minHeight:'10px', mr:'0.75rem', fontWeight:600}} disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />
                    })}
                </Tabs>
                <Box sx={{ mb: 2 }} />
                {ACCOUNT_TABS.map((tab) => {
                    const isMatched = tab.value === currentTab;
                    return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                })}
            </Box>
        </>
    );
}
export default PostInfoComponent;