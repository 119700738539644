import { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, CircularProgress } from "@mui/material";
import HttpServices from "src/services/httpService";
import { paymentModuleUrl } from "src/services/urlService";
import { CommonHelper } from "src/utils/commonHelper";
import { LocalStorage } from "src/utils/storage/local-storage";
import { useToaster } from "src/utils/toaster/toasterContext";

const PaymentRedirect = () => {
    const navigate = useNavigate();
    const popupWindowURL = new URL(window.location.href);
    const paymentIntentClientSecret = popupWindowURL.searchParams.get("payment_intent_client_secret");
    const paymentIntent = popupWindowURL.searchParams.get("payment_intent");
    const [seconds, setSeconds] = useState(60);
    const [retryPayment, setRetryPayment] = useState(true);
    const { showToast } = useToaster();
    const paymentRetrySeconds = 10000;
    let intervalId = null;
    let secondsIntervalId = null;

    useEffect(() => {
        if (!paymentIntent || !paymentIntentClientSecret) {
            showToast("Missing payment information.", "e");
            navigate("/payment-failure");
            return;
        }
        intervalId = setInterval(() => {
            if (retryPayment) {
                setRetryPayment(false);
                checkPaymentSuccess();
            }
        }, paymentRetrySeconds);
        checkPaymentSuccess();

        secondsIntervalId = setInterval(() => {
            setSeconds(prevSeconds => {
                if (prevSeconds === 0) {
                    clearInterval(secondsIntervalId);
                    paymentFailureCallback("Payment timeout occurred.",prevSeconds);
                    return 0;
                }
                return prevSeconds - 1;
            });
        }, 1000);

        return (() => {
            clearInterval(intervalId);
            clearInterval(secondsIntervalId);
        })
    }, [])
    const checkPaymentSuccess = () => {
        getPaymentResponse();
    }
    const paymentSuccessCallback = (data, message) => {
        if (data.status) {
            LocalStorage.setItem('token', data.token);
            CommonHelper.GetCurrentPlan();
            navigate("/payment-success");
        }
        else
            setRetryPayment(true);
    }
    const paymentFailureCallback = (message,currentSecond) => {
        if (currentSecond === 0) {
            showToast(message, "e");
            navigate("/payment-failure"); 
        }
    };
    const getPaymentResponse = () => {
            let url = `${paymentModuleUrl.checkPayment}${paymentIntent}&payment_intent_client_secret=${paymentIntentClientSecret}`;
            let postProps = {
                url,
                successCallback: paymentSuccessCallback,
                failureCallback: paymentFailureCallback
            }
            HttpServices.Post(postProps);
      
    }
    return (
        <Box sx={{ textAlign: 'center', mt: 5 }}>
        <CircularProgress size={60} sx={{ mb: 3 }} />
        <Typography variant="h6" gutterBottom>
            Processing Your Payment
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
            Please wait for {seconds} seconds while we process your payment. 
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
            Do not refresh or close this page.
        </Typography>
    </Box>
    )
}
export default PaymentRedirect;