import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import StripePaymentComponent from "src/components/stripe/stripe-payment.component";

const PaymentDialogComponent = ({ clientSecret, amount, handleClose }) => {
    return (
        <Dialog onClose={() => { handleClose(false) }} open>
            <DialogTitle>Payment</DialogTitle>
            <DialogContent dividers>
                <StripePaymentComponent clientSecret={clientSecret} amount={amount} />
            </DialogContent>
        </Dialog>
    );
}
export default PaymentDialogComponent;