import { Box, Tab, Tabs, Typography, tabsClasses } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { capitalCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import HttpServices from 'src/services/httpService';
import { integrateModuleURL } from 'src/services/urlService';
import Facebook_Icon from '../../assets/facebook.svg';
import Gbusiness_Icon from '../../assets/google-my-business-logo.svg';
import Instagram_Icon from '../../assets/instagram.svg';
import LinkedIn_Icon from '../../assets/linked_in.svg';
import Youtube_Icon from '../../assets/youtube.png';
import FacebookPostGrid from './facebookPosts/facebookPostGrid.component';
import GoogleMyBusinessPostGrid from './googlemybusinessPosts/googlemybusinessPostGrid.component';
import InstagramPostGrid from './instagramPosts/instagramPostGrid.component';
import LinkedInPagePostGrid from './linkedInPagePosts/linkedInPostGrid.component';
import YoutubePostGrid from './youtubePosts/youtubePostGrid.component';
import { PostUtil } from '../new-post-new/post-util';

const PublishedPostComponent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  //State Variable
  const [currentTab, setCurrentTab] = useState('Facebook Page');
  const [facebookNetworks, setFacebookNetworks] = useState([]);
  const [facebookGroupNetworks, setfacebookGroupNetworks] = useState([]);
  const [instagramNetworks, setInstagramNetworks] = useState([]);
  const [youtubeNetworks, setYoutubeNetworks] = useState([]);
  const [twitterNetworks, setTwitterNetworks] = useState([]);
  const [linkedInNetworks, setLinkedInNetworks] = useState([]);
  const [tiktokNetworks, setTiktokNetworks] = useState([]);
  const [gbusinessNetworks, setGBusinessNetworks] = useState([]);
  const [isNetworksLoaded, setNetworkLoaded] = useState(false);

  const ACCOUNT_TABS = [
    {
      value: 'Facebook Page',
      icon: <img src={Facebook_Icon} width={28} height={20} />,
      component: <FacebookPostGrid connectedNetworks={facebookNetworks} />,
    },
    {
      value: 'Instagram',
      icon: <img src={Instagram_Icon} width={28} height={20} />,
      component: <InstagramPostGrid connectedNetworks={instagramNetworks} />,
    },
    {
      value: 'Youtube',
      icon: <img src={Youtube_Icon} width={35} height={35} />,
      component: <YoutubePostGrid connectedNetworks={youtubeNetworks} />,
    },
    {
      value: 'LinkedIn Page',
      icon: <img src={LinkedIn_Icon} width={28} height={20} />,
      component: <LinkedInPagePostGrid connectedNetworks={linkedInNetworks} />,
    },
    {
      value: 'GoogleMyBusiness',
      icon: <img src={Gbusiness_Icon} width={28} height={20} />,
      component: <GoogleMyBusinessPostGrid connectedNetworks={gbusinessNetworks} />,
    },
    // {
    //   value: 'TikTok',
    //   icon: <img src={Tiktok_Icon} width={28} height={20} />,
    //   component: <TikTokPagePostGrid connectedNetworks={tiktokNetworks} />,
    // }
  ];

  useEffect(() => {
    getConnectedNetworks();
  }, []);

  useEffect(() => {}, [
    facebookNetworks,
    instagramNetworks,
    youtubeNetworks,
    twitterNetworks,
    linkedInNetworks,
    tiktokNetworks,
    gbusinessNetworks,
    isNetworksLoaded,
  ]);
  const connectedNetworksSuccessCallback = (data, message) => {
    let fNetworks = data.filter(
      (a) =>
        a.service_name.toUpperCase() === PostUtil.ServiceName.FACEBOOK &&
        a.profile_type == PostUtil.ProfileType.PAGE &&
        a.is_enabled
    );
    let fgNetworks = data.filter(
      (a) =>
        a.service_name.toUpperCase() === PostUtil.ServiceName.FACEBOOK &&
        a.profile_type == PostUtil.ProfileType.GROUP &&
        a.is_enabled
    );
    let iNetworks = data.filter((a) => a.service_name.toUpperCase() === PostUtil.ServiceName.INSTAGRAM && a.is_enabled);
    let yNetworks = data.filter((a) => a.service_name.toUpperCase() === PostUtil.ServiceName.YOUTUBE && a.is_enabled);
    let tNetworks = data.filter((a) => a.service_name.toUpperCase() === PostUtil.ServiceName.TWITTER && a.is_enabled);
    let gNetworks = data.filter((a) => a.service_name.toUpperCase() === PostUtil.ServiceName.GBUSINESS && a.is_enabled);
    let lNetworks = data.filter(
      (a) => a.service_name.toUpperCase() === PostUtil.ServiceName.LINKEDIN && a.profile_type === 'PAGE' && a.is_enabled
    );
    // let lNetworks = data.filter(a => a.service_name.toUpperCase() === PostUtil.ServiceName.LINKEDIN);
    let tiNetworks = data.filter((a) => a.service_name.toUpperCase() === PostUtil.ServiceName.TIKTOK);
    setFacebookNetworks(fNetworks);
    setfacebookGroupNetworks(fgNetworks);
    setInstagramNetworks(iNetworks);
    setYoutubeNetworks(yNetworks);
    setTwitterNetworks(tNetworks);
    setLinkedInNetworks(lNetworks);
    setTiktokNetworks(tiNetworks);
    setGBusinessNetworks(gNetworks);
    setNetworkLoaded(true);
  };
  const connectedNetworkFailureCallback = (message) => {
    setFacebookNetworks([]);
    setInstagramNetworks([]);
    setYoutubeNetworks([]);
    setTwitterNetworks([]);
    setLinkedInNetworks([]);
    setTiktokNetworks([]);
    setGBusinessNetworks([]);
  };
  const getConnectedNetworks = () => {
    let getProps = {
      url: integrateModuleURL.getConnectedNetworks,
      successCallback: connectedNetworksSuccessCallback,
      failureCallback: connectedNetworkFailureCallback,
    };
    HttpServices.Get(getProps);
  };

  return (
    <>
      <Helmet>
        <title> Published Post </title>
      </Helmet>
      <Box>
        <Tabs
          value={currentTab}
          variant="scrollable"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { display:'none' },
            },
            ...(!isMobile && { gap: '0.5rem', minHeight: '37px' }),
          }}
          allowScrollButtonsMobile
          onChange={(e, value) => setCurrentTab(value)}
        >
          {ACCOUNT_TABS.map((tab) => {
            return (
              <Tab
                disableRipple
                key={tab.value}
                label={isMobile ? '' : capitalCase(tab.value)}
                icon={tab.icon}
                value={tab.value}
                sx={{
                  ...(!isMobile && {
                    flexDirection: 'row',
                    gap: '0.25rem',
                    padding: 0,
                    minHeight: '10px',
                    mr: '0.75rem',
                    fontWeight: 600,
                  }),
                }}
              />
            );
          })}
        </Tabs>
      </Box>
      <Box sx={{ mb: 2 }} />
      {isNetworksLoaded &&
        ACCOUNT_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
    </>
  );
};

export default PublishedPostComponent;
