import { useTheme } from "@emotion/react";
import { TableCell, TableHead, TableRow, useMediaQuery } from "@mui/material";


const FailedPostNestedGridHead = () => {

    const getTableDesk = [
        { id: 'updated_at', label: 'Updated', alignRight: false },
        { id: 'description', label: 'Post  Content', alignRight: false },
        { id: 'media', label: 'Media', alignRight: false },
        { id: 'failure-reason', label: 'failure-reason', alignRight: false },
        { id: 'post_id', label: 'Post Id', alignRight: false },
        { id: 'scheduled-for', label: 'Scheduled For', alignRight: false },
        { id: 'action', label: 'Action', alignRight: true },
    ];
    const getTableMobile = [
        { id: 'description', label: 'Post  Content', alignRight: false },
        { id: 'failure-reason', label: 'failure-reason', alignRight: false },
        { id: 'updated_at', label: 'Updated', alignRight: false },
        { id: 'action', label: 'Action', alignRight: true },
    ];
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const TABLE_HEAD = isMobile ? getTableMobile : getTableDesk;


    return (
        <TableHead>
            <TableRow>
                {TABLE_HEAD.map((row, i) => {
                    return (
                        <>
                            <TableCell align={row.alignRight ? 'right' : 'left'}>{row.label}</TableCell>
                        </>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}

export default FailedPostNestedGridHead;