import { ErrorOutline as ErrorOutlineIcon, ScheduleSend as ScheduleSendIcon, Send as SendIcon } from '@mui/icons-material';
import { LoadingButton } from "@mui/lab";
import { Card, FormControlLabel, IconButton, Stack, TextField, Typography } from "@mui/material";
import { createTheme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LocalizationProvider, MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePickerToolbar } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from "dayjs";
import { SVGComponent } from "src/components/svg/svg-component";
import { CommonHelper } from "src/utils/commonHelper";
import Savedraft from '../../assets/icons/save-draft.svg';
import { NewPostStyledComponent } from "./newPostStyled.component";

const PostFooter = ({
    isSubmittingDraft,
    handleSaveDraft,
    scheduleDateTime,
    handleScheduleDate,
    whenToPost,
    PostOption,
    hasApproverPermission,
    handlePostTypeChange,
    handleFormsubmit,
    isSubmitting
}) => {

    //Common Context
    const theme = useTheme();
    const customTheme = createTheme({
        breakpoints: {
            values: {
                mdCustom: 1100,
            },
        },
    });

    const isDesktop = useMediaQuery(customTheme.breakpoints.up('mdCustom'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const submitButtonView = () => {
        let submitButtonName = PostOption.PostNow.Label;
        switch (whenToPost) {
            case PostOption.PostNow.Name:
                submitButtonName = PostOption.PostNow.Label;
                break;
            case PostOption.SchedulePost.Name:
                submitButtonName = PostOption.SchedulePost.Label;
                break;
            default:
                break;
        }
        return submitButtonName;
    };

    const CustomDateTimePickerToolbar = (props) => {
        return (
            <DateTimePickerToolbar {...props} ampmInClock>
                <div style={{ position: 'absolute', top: 16, right: 16 }}>
                    {props.ampm && (
                        <>
                            <span style={{ fontSize: '1.5rem' }}>:</span>
                            <span style={{ fontSize: '1rem', marginLeft: '4px' }}>{props.ampm}</span>
                        </>
                    )}
                </div>
            </DateTimePickerToolbar>
        );
    };

    return (
        <>
            {!isDesktop ? <Card style={{ width: '100%', marginBottom: '7.5rem', marginLeft: '1rem', marginTop: '0.2rem', padding: '0.7rem' }} >
                <Stack display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ height: '3rem' }}>
                    <IconButton
                        onClick={handleSaveDraft}
                    >
                        <img src={Savedraft} alt="Savedraft_Icon"
                            width={25}
                            height={25} />
                    </IconButton>
                    <Stack display="flex" flexDirection="row" style={{ alignItems: 'center' }}>
                        {whenToPost === PostOption.SchedulePost.Name && !isMobile &&
                            <Stack direction="row" alignItems="center" sx={{ mr: 2 }}>
                                <ErrorOutlineIcon sx={{ color: theme.palette.primary.main, mr: 0.5 }} />
                                <Typography variant="caption" color="black">
                                    YOUR POST SCHEDULED ON
                                    <span
                                        style={{
                                            cursor: 'pointer',
                                            textDecoration: 'underline',
                                            color: theme.palette.primary.main,
                                            marginLeft: '4px',
                                        }}
                                    >
                                        {CommonHelper.formatDateAndTime(scheduleDateTime)}
                                    </span>
                                </Typography>
                            </Stack>
                        }
                        {whenToPost === PostOption.SchedulePost.Name && (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDateTimePicker
                                    placeholder="Select Time"
                                    value={dayjs(scheduleDateTime)}
                                    onChange={handleScheduleDate}
                                    disabled={whenToPost !== PostOption.SchedulePost.Name}
                                    renderInput={(props) => (
                                        <TextField
                                            {...props}
                                            size="small"
                                        />
                                    )}
                                    ToolbarComponent={CustomDateTimePickerToolbar}
                                />
                            </LocalizationProvider>
                        )}
                        <FormControlLabel
                            control={
                                <NewPostStyledComponent.SwitchStyle
                                    sx={{ mr: whenToPost !== PostOption.SchedulePost.Name && 1, ml: 2 }}
                                    size="small"
                                    checked={whenToPost === PostOption.SchedulePost.Name}
                                    onChange={handlePostTypeChange}
                                />
                            }
                            label={whenToPost !== PostOption.SchedulePost.Name && <Typography variant="body1" color="black">Is Scheduled Post</Typography>}
                        />
                        <IconButton
                            onClick={handleFormsubmit}
                        >{whenToPost === PostOption.SchedulePost.Name ? <ScheduleSendIcon style={{ color: theme.palette.primary.main }} /> : <SendIcon style={{ color: theme.palette.primary.main }} />}

                        </IconButton>
                    </Stack>
                </Stack>
            </Card> :
                <Card style={{ width: '100%', marginLeft: '1rem', marginTop: '0.2rem', padding: '0.7rem', marginBottom: '4rem' }}>
                    <Stack display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ height: '3.5rem' }}>
                        <LoadingButton
                            variant="contained"
                            loading={isSubmittingDraft}
                            endIcon={<SVGComponent.DraftIcon />}
                            onClick={handleSaveDraft}
                        >
                            Save As Draft
                        </LoadingButton>
                        <Stack display="flex" flexDirection="row" style={{ alignItems: 'center' }}>
                            {whenToPost === PostOption.SchedulePost.Name && (
                                <Stack direction="row" alignItems="center" sx={{ mr: 2 }}>
                                    <ErrorOutlineIcon sx={{ color: theme.palette.primary.main, mr: 0.5 }} />
                                    <Typography variant="caption" color="black">
                                        YOUR POST SCHEDULED ON
                                        <span
                                            style={{
                                                cursor: 'pointer',
                                                textDecoration: 'underline',
                                                color: theme.palette.primary.main,
                                                marginLeft: '4px',
                                            }}
                                        >
                                            {CommonHelper.formatDateAndTime(scheduleDateTime)}
                                        </span>
                                    </Typography>
                                </Stack>
                            )}
                            {whenToPost === PostOption.SchedulePost.Name && (
                                <LocalizationProvider dateAdapter={AdapterDayjs} style={{ height: "30px" }}>
                                     <MobileDateTimePicker
                                        placeholder="Select Time"
                                        value={dayjs(scheduleDateTime)}
                                        onChange={handleScheduleDate}
                                        disabled={whenToPost !== PostOption.SchedulePost.Name}
                                        style={{ height: "30px" }}
                                        renderInput={(props) => (
                                            <TextField
                                                {...props}
                                                size="small"
                                            />
                                        )}
                                        ToolbarComponent={CustomDateTimePickerToolbar}
                                    />
                                </LocalizationProvider>
                            )}
                            <FormControlLabel
                                control={
                                    <NewPostStyledComponent.SwitchStyle
                                        sx={{ mr: 1, ml: 2 }}
                                        checked={whenToPost === PostOption.SchedulePost.Name}
                                        onChange={handlePostTypeChange}
                                        disabled={!hasApproverPermission}
                                    />
                                }
                                label={whenToPost !== PostOption.SchedulePost.Name && <Typography variant="body1" color="black">Is Scheduled Post</Typography>}
                            />
                            <LoadingButton
                                variant="contained"
                                loading={isSubmitting}
                                endIcon={<SendIcon />}
                                onClick={handleFormsubmit}
                            >
                                {submitButtonView()}
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </Card>}
        </>
    )
}
export default PostFooter;