import { useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
// @mui
import { Box, Divider, Stack } from '@mui/material';
// redux
// import { useDispatch, useSelector } from '../../../redux/store';
// import {
//   addRecipients,
//   onSendMessage,
//   getConversation,
//   getParticipants,
//   markConversationAsRead,
//   resetActiveConversation,
// } from '../../../redux/slices/chat';
// routes
// import { PATH_DASHBOARD } from '../../../routes/paths';
//
import ChatRoom from './ChatRoom';
import ChatMessageList from './ChatMessageList';
import ChatHeaderDetail from './ChatHeaderDetail';
import ChatMessageInput from './ChatMessageInput';
import ChatHeaderCompose from './ChatHeaderCompose';
import { de } from 'date-fns/locale';

const conversationSelector = (state) => {
  const { conversations, activeConversationId } = state.chat;
  const conversation = activeConversationId ? conversations.byId[activeConversationId] : null;
  if (conversation) {
    return conversation;
  }
  const initState = {
    id: '',
    messages: [],
    participants: [],
    unreadCount: 0,
    type: '',
  };
  return initState;
};

export default function ChatWindow({ selectedConversation, handleSendMessage, handleNextMessage, handleDeleteMessage ,userPic,isNextChatMessageLoading,userId ,isSendLoading}) {
  // const mode = conversationKey ? 'DETAIL' : 'COMPOSE';
  // const displayParticipants = participants.filter((item) => item.id !== '8864c717-587d-472a-929a-8e5f298024da-0');
  const conversationId = selectedConversation?.from.id == userId ? selectedConversation?.to.id : selectedConversation?.from.id
  return (
    <Stack sx={{ flexGrow: 1, minWidth: '1px' }}>
      {/* {mode === 'DETAIL' ? (
        <ChatHeaderDetail participants={displayParticipants} />
      ) : (
        <ChatHeaderCompose
          recipients={recipients}
          contacts={Object.values(contacts.byId)}
          onAddRecipients={handleAddRecipients}
        />
      )} */}

      <Divider />

      <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
        <Stack sx={{ flexGrow: 1 }}>
          <ChatMessageList conversation={selectedConversation} handleNextMessage={handleNextMessage} isNextChatMessageLoading={isNextChatMessageLoading} handleDeleteMessage={handleDeleteMessage} userPic={userPic} userId={userId}/>

          <Divider />

          <ChatMessageInput
            conversationId={conversationId}
            onSend={handleSendMessage}
            isSendLoading={isSendLoading}
          // disabled={pathname === PATH_DASHBOARD.chat.new}
          />
        </Stack>

        {/* {mode === 'DETAIL' && <ChatRoom conversation={conversation} participants={displayParticipants} />} */}
      </Box>
    </Stack>
  );
}
