import { Box, Card } from '@mui/material';
import { useEffect, useState } from 'react';
import HttpServices from 'src/services/httpService';
import { postsModuleURL } from 'src/services/urlService';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import ScheduledPostGrid from './Grid/PostGrid.component';
import NoUpcomingPost from '../../assets/app-image/no-upcoming-posts.svg';

const ScheduledPost = () => {

  const { showToast } = useToaster();

  //State variables
  const [scheduledPostList, setScheduledPostList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();

  useEffect(() => {
    getScheduledPost('scheduled_time', 'asc');
  }, []);

  const postSuccessCallback = (data, message) => {
    message && showToast(message, 's');
    setScheduledPostList(data);
    setLoader(false);
  };

  const failureCallback = (message) => {
    message && showToast(message, 'e');
    setLoader(false);
  };

  const getScheduledPost = (sortBy = '', sortOrder = '') => {
    setOrder(sortOrder);
    setOrderBy(sortBy);
    let currentDate = new Date();
    let tDate = currentDate.setDate(currentDate.getDate() + 365);
    currentDate.setHours(0, 0, 0, 0);
    tDate = new Date(currentDate).toISOString();
    let getProps = {
      url: `${
        postsModuleURL.getApprovedCalendarEvents
        }${new Date().toISOString()}&to=${tDate}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      successCallback: postSuccessCallback,
      failureCallback,
    };
    HttpServices.Get(getProps);
  };

  const handleReCallScheduledPost = (sortBy = '', sortOrder = '') => {
    setLoader(true);
    getScheduledPost(sortBy, sortOrder);
  };

  return (
    <Box>
      {loader ? (
        <Card style={{minHeight: 'calc(100vh - 150px)', maxHeight: 'calc(100vh - 150px)'}}>
          <Spinner />
        </Card>
      ) : (
        <>
          {scheduledPostList && scheduledPostList.length > 0 ? (
            <Card>
              <ScheduledPostGrid
                scheduledPostList={scheduledPostList}
                orderBy={orderBy}
                order={order}
                handleReCallScheduledPost={handleReCallScheduledPost}
              />
            </Card>
          ) : (
            <Card style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 750, minHeight: 'calc(100vh - 150px)', maxHeight: 'calc(100vh - 150px)' }}>
              <img src={NoUpcomingPost} style={{ width: '50%', height: '50%', maxWidth: '50%', maxHeight: '50%' }} alt="No Upcoming Post" />
            </Card>
          )}
        </>
      )}
    </Box>
  );
};
export default ScheduledPost;
