import { Backdrop, useTheme } from '@mui/material';
import { ThreeDots } from 'react-loader-spinner';

const ThreeDotLoader = ({  width = '25', minHeight = '50px' ,display='flex',justifyContent='center' ,height= 'calc(100vh - 133px)'}) => {
  const theme = useTheme();
  return (
    <div
      style={{ display, justifyContent, minHeight, height}}
    >
      <ThreeDots
        visible={true}
        height={25}
        width={width}
        color={theme.palette.primary.main}
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
};
export default ThreeDotLoader;
