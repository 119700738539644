import { useEffect, useState, useRef } from 'react';
import ChatMessageItem from './ChatMessageItem';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import ThreeDotLoader from 'src/utils/Loader/threeDotLoader.compontent';
// import LightboxModal from 'src/components/LightboxModal';

export default function ChatMessageList({ conversation, handleNextMessage,isNextChatMessageLoading, handleDeleteMessage, userId, userPic }) {
  
  const scrollRef = useRef(null);
  const theme = useTheme();
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [reversedMessages, setReversedMessages] = useState([]);

  useEffect(() => {
    setReversedMessages([]);
    if (conversation?.messages) {
      // Reverse the messages once and store them in state
      setReversedMessages([...conversation.messages].reverse());
    }
  }, [conversation]);

  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
    scrollMessagesToBottom();
  }, [reversedMessages]);

  const handleOpenLightbox = (url) => {
    const imagesLightbox = conversation?.messages
    .filter((message) => message.contentType === 'image')
    .map((message) => message.body);
    const selectedImageIndex = imagesLightbox.findIndex((image) => image === url);
    setOpenLightbox(true);
    setSelectedImage(selectedImageIndex);
  };

  const handleMoreMessage = () => {
    handleNextMessage(conversation.pagination);
  };

  return (
    <>
        <Stack sx={{ml:1}}>
          {isNextChatMessageLoading ? (
            <ThreeDotLoader justifyContent ="" display = "" height="" sx={{ml:2}}/>
          ) : (
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                textDecoration: 'underline',
                cursor: 'pointer',
                color: theme.palette.grey[500],
              }}
              onClick={handleMoreMessage}
            >
             More Messages
            </Typography>
          )}
        </Stack>
      <Scrollbar scrollableNodeProps={{ ref: scrollRef }} sx={{ p: 3, height: 1 }}>
        {reversedMessages.map((message) => (
          <ChatMessageItem
            key={message.id}
            message={message}
            conversation={conversation}
            onOpenLightbox={handleOpenLightbox}
            handleDeleteMessage={handleDeleteMessage}
            userId={userId}
            userPic={userPic}
          />
        ))}
      </Scrollbar>

      {/* <LightboxModal
        images={imagesLightbox}
        mainSrc={imagesLightbox[selectedImage]}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onCloseRequest={() => setOpenLightbox(false)}
      /> */}
    </>
  );
}
