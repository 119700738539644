import { Card, TextField, Button, Box, CircularProgress } from "@mui/material";
import { useState } from "react";

import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { useToaster } from 'src/utils/toaster/toasterContext';

const AppSumoUpgradeComponent = () => {
    const { showToast } = useToaster();
    const [token, setToken] = useState("");
    const [loading, setLoading] = useState(false);
    const [height, setHeight] = useState(window.innerHeight - 150);


    const failureCallback = (message) => {
        showToast(message, "e");
        setLoading(false); // Stop loading on failure
    }

    const successCallback = (data, message) => {
        showToast(message, "s");
        setLoading(false); // Stop loading on success
    }

    const createPaymentLink = () => {
        setLoading(true); // Start loading
        let form = {
            appsumo_token: token
        }
        let postProps = {
            url: userModuleURL.updateAppSumoAccount,
            body: form,
            successCallback,
            failureCallback
        }
        HttpServices.Post(postProps);
    };

    return (
        <Card sx={{ padding: 2, height: height }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 400, margin: 'auto' }}>
                <TextField
                    label="AppSumo Token"
                    variant="outlined"
                    value={token}
                    onChange={(e) => setToken(e.target.value)}
                    fullWidth
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={createPaymentLink}
                    disabled={loading} // Disable button when loading
                    endIcon={loading ? <CircularProgress size={24} /> : null} // Show loading spinner
                    size="medium"
                >
                    {loading ? 'Loading...' : 'Submit'}
                </Button>
            </Box>
        </Card>
    );
}

export default AppSumoUpgradeComponent;
