import { useTheme } from '@emotion/react';
import { Hidden, ListItemText, TableBody, useMediaQuery } from '@mui/material';
import { StyledComponent } from 'src/components/styled-components/styledComponent';
import { useRightSidebar } from 'src/layouts/right-side-bar/rightSidebarProvider';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import { PostDetailsHelper } from 'src/utils/postDetailsHelper';
import { useToaster } from 'src/utils/toaster/toasterContext';
import PostPreview from '../Preview/post-preview.component';
import ScheduledPostGridAction from './PostGridAction.component';

const ScheduledPostGridBody = ({ scheduledPostList, page, rowsPerPage, handleReCallScheduledPost }) => {

  const theme = useTheme();
  const { showToast } = useToaster();
  const { showRightSidebar } = useRightSidebar();
  let maxWidth = useMediaQuery(theme.breakpoints.down('sm')) ? 500 : 400;

  const handleOpenScheduledPost = (row, open = false) => {
    let data = {
      content: postView,
      data: row,
    };
    showRightSidebar(data, '500px');
  };

  const postView = (row) => {
    let postDetails = {
      data: row,
    };
    return <PostPreview postDetails={postDetails}></PostPreview>;
  };

  const handleToggleScheduledPost = (event, scheduledPost) => {
    event.stopPropagation();
    let bodyProps = {
      schedule_post_id: scheduledPost.id,
    };
    let postProps = {
      url: scheduledPost.is_enabled ? userModuleURL.disableScheduledPost : userModuleURL.enableScheduledPost,
      body: bodyProps,
      successCallback: toggleScheduledPostSuccessCallback,
      failureCallback: toggleScheduledPostFailureCallback,
    };
    HttpServices.Post(postProps);
  };

  const toggleScheduledPostSuccessCallback = (data, message) => {
    showToast(message, 's');
    handleReCallScheduledPost();
  };

  const toggleScheduledPostFailureCallback = (message) => {
    showToast(message, 'e');
    handleReCallScheduledPost();
  };

  return (
    <TableBody>
      {scheduledPostList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
        <StyledComponent.StyledTableRow key={i} onClick={() => handleOpenScheduledPost(row, true)}>
          <StyledComponent.StyledTableCell>
            {CommonHelper.getTableDateAndTime(row.scheduled_time)}
          </StyledComponent.StyledTableCell>
          <Hidden only={['xs']}>
            <StyledComponent.StyledTableCell>
              <ListItemText
                sx={{
                  m: 0,
                  '.MuiListItemText-primary': {
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontSize: '0.875rem',
                  },
                  '.MuiListItemText-secondary': {
                    color: '#637381',
                    fontSize: '0.75rem',
                    textTransform: 'lowercase',
                  },
                }}
                primary={CommonHelper.formatDateAndTime(row.created_time, 'date')}
                secondary={CommonHelper.formatDateAndTime(row.created_time, 'time')}
              />
            </StyledComponent.StyledTableCell>
          </Hidden>
          <Hidden only={['xs']}>
            <StyledComponent.StyledTableCell>
              <ListItemText
                sx={{
                  m: 0,
                  '.MuiListItemText-primary': {
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontSize: '0.875rem',
                  },
                  '.MuiListItemText-secondary': {
                    color: '#637381',
                    fontSize: '0.75rem',
                    textTransform: 'lowercase',
                  },
                }}
                primary={CommonHelper.formatDateAndTime(row.updated_at, 'date')}
                secondary={CommonHelper.formatDateAndTime(row.updated_at, 'time')}
              />
            </StyledComponent.StyledTableCell>
          </Hidden>
          <StyledComponent.StyledTableCell>
            {PostDetailsHelper.GetPostContent(row.description, maxWidth)}
          </StyledComponent.StyledTableCell>
          <Hidden only={['xs']}>
            <StyledComponent.StyledTableCell onClick={(event) => event.stopPropagation()}>
              {PostDetailsHelper.getPostMedias(row.media_url)}
            </StyledComponent.StyledTableCell>
          </Hidden>
          <StyledComponent.StyledTableCell>
            {PostDetailsHelper.getProfile(row.created_by.name, row.created_by.email, row.created_by.profile_pic)}
          </StyledComponent.StyledTableCell>
          <StyledComponent.StyledTableCell>
            {PostDetailsHelper.getProfile(row.approved_by.name, row.approved_by.email, row.approved_by.profile_pic)}
          </StyledComponent.StyledTableCell>
          <StyledComponent.StyledTableCell>
            <StyledComponent.SwitchIcon
              disabled={!row.has_edit_access}
              checked={row.is_enabled}
              onClick={(event) => handleToggleScheduledPost(event, row)}
            />
          </StyledComponent.StyledTableCell>
          <Hidden only={['xs']}>
            <StyledComponent.StyledTableCell>
              {PostDetailsHelper.getSocialMediaViewWithTooltip(row.connected_profile_details)}
            </StyledComponent.StyledTableCell>
          </Hidden> 
          <StyledComponent.StyledTableCell  onClick={(event) => event.stopPropagation()} align='center'>
              <ScheduledPostGridAction scheduledPost={row} handleReCallScheduledPost={handleReCallScheduledPost} />
          </StyledComponent.StyledTableCell>
        </StyledComponent.StyledTableRow>
      ))}
    </TableBody>
  );
};

export default ScheduledPostGridBody;
