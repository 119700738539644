import {
  Box,
  Card,
  Stack,
  Typography,
  TextField,
  Grid,
  IconButton,
  Button,
  useMediaQuery,
  createTheme,
} from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import Label from '../../components/Label';
import GppBadIcon from '@mui/icons-material/GppBad';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const RootStyle = styled(Card)(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(3),
  [theme.breakpoints.up(414)]: {
    padding: theme.spacing(5),
  },
  // boxShadow: theme.customShadows.z8,
  borderWidth: `1px !important`,
  borderColor: `${alpha(theme.palette.grey[500], 1)} !important`,

  // Adding transition for smooth effect
  transition: 'transform 0.3s ease-out',
}));

const PricingPlanCard = ({
  name,
  price,
  no_of_scheduled_posts,
  no_of_draft,
  no_of_social_account,
  id,
  selectedPlan,
  handlePlan,
  is_current_plan,
  plan_validity,
  no_of_users,
  no_of_brands,
  no_of_scheduled_days_ahead,
  total_storage,
  has_cname,
  handleUpdate,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const customTheme = createTheme({
    breakpoints: {
      values: {
        mdCustom: 1100,
      },
    },
  });

  const responsiveFontSize = useMediaQuery(theme.breakpoints.between('md', 'xl'));

  const planDetailsFontSize = () => {
    if (responsiveFontSize) return '1rem';
    return '1rem';
  };

  const getInitialStruct = () => [
    { Value: 'no_of_social_accounts', DisplayName: 'Social Account', Price: no_of_social_account },
    { Value: 'no_of_users', DisplayName: 'Users', Price: no_of_users },
    { Value: 'no_of_brands', DisplayName: 'Brands', Price: no_of_brands },
    { Value: 'no_of_scheduled_posts', DisplayName: 'Scheduled Posts', Price: no_of_scheduled_posts },
    { Value: 'no_of_scheduled_days_ahead', DisplayName: 'Scheduled Post Ahead', Price: no_of_scheduled_days_ahead },
    { Value: 'no_of_drafts', DisplayName: 'Drafts', Price: no_of_draft },
    { Value: 'no_of_storage', DisplayName: 'Storage', Price: total_storage },
  ];

  const UpgradeStruct = {
    no_of_social_accounts: 0,
    no_of_users: 0,
    no_of_brands: 0,
    no_of_scheduled_posts: 0,
    no_of_scheduled_days_ahead: 0,
    no_of_drafts: 0,
    no_of_storage: 0,
    has_c_name: false,
  };

  const [addons, setAddons] = useState(getInitialStruct);
  const [upgradeItems, setUpgradeItems] = useState(UpgradeStruct);

  const handleInputChange = (value, addon) => {
    setUpgradeItems((prevItems) => ({
      ...prevItems,
      [addon.Value]: value,
    }));
  };

  const incrementValue = (addon) => {
    handleInputChange(upgradeItems[addon.Value] + 1, addon);
  };

  const decrementValue = (addon) => {
    handleInputChange(Math.max(0, upgradeItems[addon.Value] - 1), addon); // Ensure value doesn't go below 0
  };
  const handleUpdateAddon = () => {
    handleUpdate(upgradeItems);
  };
  const handleChangePlan = (id) => {
    setUpgradeItems({ ...UpgradeStruct });
    handlePlan(id);
  };
  const handleReset = () => {
    setUpgradeItems({ ...UpgradeStruct });
    handleUpdate({ ...UpgradeStruct });
  };

  const getBackGroundColor = () => {
    const lightGreen = theme.palette.pricing.lightGreen;
    const lightRed = `linear-gradient(140deg, #00C853 0%, #B9F6CA 100%)`;
    return is_current_plan ? lightRed : selectedPlan?.id === id ? lightGreen : '';
  };

  return (
    // <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, p: 1 }}>
    //   {/* Main Plan Details */}
    //   <Box
    //     sx={{
    //       flex: selectedPlan?.id === id ? { xs: '1 1 auto', md: '7' } : '1 1 100%',
    //       background: getBackGroundColor(),
    //       cursor: 'pointer',
    //       position: 'relative',
    //       p: 2,
    //       borderRadius:'10px',
    //       boxShadow:4
    //     }}
    //     onClick={() => handleChangePlan(id)}
    //   >
    //     {is_current_plan && (
    //       <Label
    //         sx={{
    //           position: 'absolute',
    //           top: { xs: 4, sm: 16 },
    //           right: { xs: 8, sm: 16 },
    //           background: 'white',
    //         }}
    //       >
    //         CURRENT PLAN
    //       </Label>
    //     )}
    //     <Typography variant="overline" sx={{ color: 'text.secondary' }}>
    //       {name.replace('_', ' ')}
    //     </Typography>

    //     <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
    //       <Typography variant="h4" sx={{ mx: 1 }}>
    //         {price === 0 ? 'Free' : `$${price}`}
    //       </Typography>
    //       {price !== 0 && (
    //         <Typography component="span" variant="subtitle2" sx={{ alignSelf: 'flex-end', color: 'text.secondary' }}>
    //           /{plan_validity === 30 ? 'MONTH' : 'YEAR'}LY
    //         </Typography>
    //       )}
    //     </Box>

    //     <Stack component="ul" spacing={2} sx={{ my: 2 }}>
    //       <Stack component="li" direction="row" alignItems="center" spacing={1.5}>
    //         <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
    //         <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
    //           {no_of_social_account} Social Profiles
    //         </Typography>
    //       </Stack>
    //       <Stack component="li" direction="row" alignItems="center" spacing={1.5}>
    //         <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
    //         <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
    //           {no_of_users} Number of Users
    //         </Typography>
    //       </Stack>
    //       <Stack component="li" direction="row" alignItems="center" spacing={1.5}>
    //         <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
    //         <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
    //           {no_of_brands} Number of Brands
    //         </Typography>
    //       </Stack>
    //       <Stack component="li" direction="row" alignItems="center" spacing={1.5}>
    //         <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
    //         <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
    //           {no_of_scheduled_posts} Scheduled Posts
    //         </Typography>
    //       </Stack>
    //       <Stack component="li" direction="row" alignItems="center" spacing={1.5}>
    //         <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
    //         <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
    //           {no_of_scheduled_days_ahead} Scheduled Posts Ahead
    //         </Typography>
    //       </Stack>
    //       <Stack component="li" direction="row" alignItems="center" spacing={1.5}>
    //         <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
    //         <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
    //           {no_of_draft} Drafts
    //         </Typography>
    //       </Stack>
    //       <Stack component="li" direction="row" alignItems="center" spacing={1.5}>
    //         <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
    //         <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
    //           {total_storage} GB Storage
    //         </Typography>
    //       </Stack>
    //       <Stack component="li" direction="row" alignItems="center" spacing={1.5}>
    //         {has_cname ? (
    //           <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
    //         ) : (
    //           <GppBadIcon sx={{ color: 'error.main', width: 20, height: 20 }} />
    //         )}
    //         <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
    //           White Label
    //         </Typography>
    //       </Stack>
    //     </Stack>
    //   </Box>

    //   {selectedPlan?.id === id && (
    //     <Box
    //       sx={{
    //         flex: { xs: '1 1 auto', md: '5' },
    //         transform: selectedPlan?.id === id ? 'translateY(0)' : 'translateY(100%)',
    //         transition: 'transform 0.3s ease-out',
    //         backgroundColor: 'background.default',
    //         borderTopRightRadius: 10,
    //         borderBottomRightRadius: 10,
    //         p: 2,
    //         display: 'flex',
    //         flexDirection: 'column',
    //         alignItems: 'center',
    //         ...(isMobile && {
    //           textAlign: 'center',
    //           justifyContent: 'center',
    //           alignItems: 'center',
    //         }),
    //         zIndex: 111,
    //       }}
    //     >
    //       <Stack>
    //         <Typography variant="overline" sx={{ color: 'text.secondary', pb: 2 }}>
    //           Add-on plan
    //         </Typography>
    //         {addons.map((addon, index) => (
    //           <Box key={index}>
    //             <Typography variant="body2" sx={{ fontSize: '0.875rem' }}>
    //               {addon.DisplayName}
    //             </Typography>
    //             <Stack direction="row" alignItems="center" sx={{ color: 'text.primary' }}>
    //               <IconButton size="small" onClick={() => decrementValue(addon)} sx={{ fontSize: '0.875rem' }}>
    //                 <RemoveIcon />
    //               </IconButton>
    //               <Typography variant="body2" sx={{ fontSize: '0.875rem', width: '60px', textAlign: 'center' }}>
    //                 {upgradeItems[addon.Value] + addon.Price}
    //               </Typography>
    //               <IconButton size="small" onClick={() => incrementValue(addon)} sx={{ fontSize: '0.875rem' }}>
    //                 <AddIcon />
    //               </IconButton>
    //             </Stack>
    //           </Box>
    //         ))}
    //       </Stack>
    //       <Stack spacing={0.5}>
    //         <Button variant="contained" onClick={handleUpdateAddon}>
    //           Save
    //         </Button>
    //         <Button variant="contained" onClick={handleReset}>
    //           Reset
    //         </Button>
    //       </Stack>
    //     </Box>
    //   )}
    // </Box>

    <Grid container sx={{ p: 1 }}>
      {/* Main Plan Details */}
      <Grid item xs={12} md={selectedPlan?.id === id ? 7 : 12}>
        <RootStyle style={{ background: getBackGroundColor() }} onClick={() => handleChangePlan(id)}>
          {is_current_plan && (
            <Label sx={{ top: { xs: 4, sm: 16 }, right: { xs: 8, sm: 16 }, position: 'absolute', background: 'white' }}>
              CURRENT PLAN
            </Label>
          )}
          <Typography variant="overline" sx={{ color: 'text.secondary' }}>
            {name.replace('_', ' ')}
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
            <Typography variant="h4" sx={{ mx: 1 }}>
              {price === 0 ? 'Free' : `$${price}`}
            </Typography>
            {price !== 0 && (
              <Typography
                gutterBottom
                component="span"
                variant="subtitle2"
                sx={{ alignSelf: 'flex-end', color: 'text.secondary' }}
              >
                /{plan_validity === 30 ? 'MONTH' : 'YEAR'}LY
              </Typography>
            )}
          </Box>

          <Stack component="ul" spacing={2} sx={{ my: 2 }}>
            <Stack component="li" direction="row" alignItems="center" spacing={1.5}>
              <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
              <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
                {no_of_social_account} Social Profiles
              </Typography>
            </Stack>
            <Stack
              component="li"
              direction="row"
              alignItems="center"
              spacing={1.5}
              sx={{ typography: 'body2', color: 'text.primary' }}
            >
              <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
              <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
                {no_of_users} Number of Users
              </Typography>
            </Stack>
            <Stack
              component="li"
              direction="row"
              alignItems="center"
              spacing={1.5}
              sx={{ typography: 'body2', color: 'text.primary' }}
            >
              <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
              <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
                {no_of_brands} Number of Brand
              </Typography>
            </Stack>
            <Stack
              component="li"
              direction="row"
              alignItems="center"
              spacing={1.5}
              sx={{ typography: 'body2', color: 'text.primary' }}
            >
              <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
              <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
                {no_of_scheduled_posts} Scheduled Posts
              </Typography>
            </Stack>
            <Stack
              component="li"
              direction="row"
              alignItems="center"
              spacing={1.5}
              sx={{ typography: 'body2', color: 'text.primary' }}
            >
              <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
              <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
                {no_of_scheduled_days_ahead} Scheduled Posts Ahead
              </Typography>
            </Stack>
            <Stack
              component="li"
              direction="row"
              alignItems="center"
              spacing={1.5}
              sx={{ typography: 'body2', color: 'text.primary' }}
            >
              <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
              <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
                {no_of_draft} Drafts
              </Typography>
            </Stack>
            <Stack
              component="li"
              direction="row"
              alignItems="center"
              spacing={1.5}
              sx={{ typography: 'body2', color: 'text.primary' }}
            >
              <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
              <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
                {total_storage} GB Storage
              </Typography>
            </Stack>
            <Stack
              component="li"
              direction="row"
              alignItems="center"
              spacing={1.5}
              sx={{ typography: 'body2', color: 'text.primary' }}
            >
              {has_cname ? (
                <VerifiedUserIcon sx={{ color: 'success.dark', width: 20, height: 20 }} />
              ) : (
                <GppBadIcon sx={{ color: 'error.main', width: 20, height: 20 }} />
              )}
              <Typography sx={{ color: theme.palette.pricing.grey, fontWeight: 800 }} variant="body2">
                White Label
              </Typography>
            </Stack>
          </Stack>
        </RootStyle>
      </Grid>

      {selectedPlan?.id === id && (
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            transform: selectedPlan?.id === id ? 'translateY(0)' : 'translateY(100%)',
            transition: 'transform 0.3s ease-out',
            backgroundColor: 'background.default',
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            ...(isMobile && {
              justifyContent: 'center',
              alignItems: 'center',
            }),
            zIndex:111
          }}
        >
          <Stack>
            <Typography variant="overline" sx={{ color: 'text.secondary', pb: 2 , pt: 2 }}>
              Add-on plan
            </Typography>
            {addons.map((addon, index) => (
              <Box>
                <Typography variant="body2" sx={{ fontSize: '0.875rem' }}>
                  {addon.DisplayName}
                </Typography>
                <Stack direction="row" alignItems="center" sx={{ color: 'text.primary' }}>
                  <IconButton size="small" onClick={() => decrementValue(addon)} sx={{ fontSize: '0.875rem' }}>
                    <RemoveIcon />
                  </IconButton>
                  <Typography variant="body2" sx={{ fontSize: '0.875rem', width: '60px', textAlign: 'center' }}>
                    {upgradeItems[addon.Value] + addon.Price}
                  </Typography>
                  <IconButton size="small" onClick={() => incrementValue(addon)} sx={{ fontSize: '0.875rem' }}>
                    <AddIcon />
                  </IconButton>
                </Stack>
              </Box>
            ))}
          </Stack>
          <Stack spacing={0.5}>
            <Button variant="contained" onClick={handleUpdateAddon}>
              Save
            </Button>
            <Button variant="contained" onClick={handleReset}>
              Reset
            </Button>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

export default PricingPlanCard;
