import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Card, Box, Grid, Typography, useTheme } from '@mui/material';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import RecentPosts from './recentPost.component';
import DashboardProfileStats from './dashboardProfileStats.component';
import Scrollbar from 'src/components/scrollbar';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import dashboard from '../../assets/app-image/dashboard.svg';
import { CommonHelper } from 'src/utils/commonHelper';

const Dashboard = () => {
  const [loader, setLoader] = useState(true);
  const [height, setHeight] = useState(window.innerHeight - 110);
  const [dashboardData, setDashboardData] = useState([]);
  const [dashboardProfileStats, setDashboardProfileStats] = useState([]);
  const navigate = useNavigate();
  const { showToast } = useToaster();
  const theme = useTheme();
  const hasIntegratePermission = CommonHelper.CheckForPermissionAvailableForUser(
    CommonHelper.PermissionList.INTEGRATE_PERMISSION
  );
  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = () => {
    let mediaProps = {
      url: userModuleURL.getDashboardData,
      successCallback,
      failureCallback,
    };
    HttpServices.Get(mediaProps);
  };

  const successCallback = (data) => {
    setLoader(false);
    setDashboardData(data.recent_posts);
    setDashboardProfileStats(data.dashbaord_profile_stats);
  };

  const failureCallback = (message) => {
    setLoader(false);
    message && showToast(message, 'e');
  };

  const navigateToIntegration = () => {
    navigate('/integration');
  };

  const isEmptyData = (data) => {
    return Object.values(data).every((arr) => arr.length === 0);
  };

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      {loader ? (
        <Spinner />
      ) : (
        <>
          <Grid container sx={{ minHeight: 'calc(100vh - 100px)', maxHeight: 'calc(100vh - 100px)' }}>
            {dashboardProfileStats && !isEmptyData(dashboardProfileStats) && (
              <>
                <Typography variant="h5">Profile Status</Typography>
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  style={{
                    overflowX: 'auto',
                    scrollbarWidth: 'thin',
                    scrollbarColor: `${theme.palette.primary.main} ${theme.palette.secondary.lighter}`,
                  }}
                >
                  {Object.keys(dashboardProfileStats).map(
                    (serviceKey, index) =>
                      dashboardProfileStats[serviceKey] &&
                      dashboardProfileStats[serviceKey].map((service, serviceIndex) => (
                        <Grid
                          item
                          key={serviceIndex}
                          sx={{ minWidth: 250, flexGrow: 1, flexShrink: 0, minHeight: 100, pr: 1, pt:0.5, pb:1 }}
                        >
                          {service && <DashboardProfileStats service={service} serviceName={serviceKey} />}
                        </Grid>
                      ))
                  )}
                </Grid>
              </>
            )}
            {dashboardData && !isEmptyData(dashboardData) && (
              <>
                <Typography variant="h5" sx={{ mt: 1 }}>
                  Recent Posts
                </Typography>
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  style={{
                    overflowX: 'auto',
                    scrollbarWidth: 'thin',
                    scrollbarColor: `${theme.palette.primary.main} ${theme.palette.secondary.lighter}`,
                  }}
                >
                  {Object.keys(dashboardData).map(
                    (serviceKey, index) =>
                      dashboardData[serviceKey] &&
                      dashboardData[serviceKey].map((service, serviceIndex) => (
                        <Grid item key={serviceIndex} sx={{ minWidth: 350, minHeight: 'calc(100vh - 200px)', maxHeight: 'calc(100vh - 200px)', mb: 3, pr: 1, pt:0.5, pb:1 }}>
                          {service && service.posts && <RecentPosts service={service} serviceName={serviceKey} />}
                        </Grid>
                      ))
                  )}
                </Grid>
              </>
            )}
          </Grid>
          {isEmptyData(dashboardData) && isEmptyData(dashboardData) && (
            <Card
              sx={{
                mb: 2,
                p: 2,
                height: height,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 'calc(100vh - 100px)',
                maxHeight: 'calc(100vh - 100px)',
              }}
            >
              <img
                src={dashboard}
                style={{ width: '50%', height: '50%', maxWidth: '50%', maxHeight: '50%' }}
                alt="No Dashboard"
              />
              {hasIntegratePermission && (
                <LoadingButton variant="contained" autoFocus onClick={navigateToIntegration} sx={{ mt: -5 }}>
                  Connect Your Social Accounts
                </LoadingButton>
              )}
            </Card>
          )}
        </>
      )}
    </>
  );
};

export default Dashboard;
