import { Box, Card, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import NextPlanBillDetails from './next-plan-bill-detials.component';
import SubscriptionTabComponent from './subscription-tab.component';

const CurrentSubscriptionDetails = ({ currentPlanDetails }) => {
  const theme = useTheme();
  const [height, setHeight] = useState(window.innerHeight);
  const hasSubscription = currentPlanDetails.has_subscription;

  const getSocialPlatformsView = () => {
    let plan = currentPlanDetails.plan_details.no_of_social_accounts;
    let total = plan.no_of_social_accounts_total;
    let currPlan = plan.no_of_social_accounts_current_used;
    let remaining = total - currPlan;
    if (remaining < 0) remaining = 0;
    return (
      <Card style={{ marginTop: 1, marginRight: 5, marginBottom: 5, padding: 10, borderRadius: 5, flex: 1 }}>
        <Typography style={{ fontWeight: 800, marginBottom: 10 }}>
          Social Platforms
        </Typography>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 5,
            color: theme.palette.text.secondary,
          }}
        >
          <Typography style={{ fontWeight: 800 }}>
            Total number of social accounts
          </Typography>
          <Typography style={{ fontWeight: 800 }}>{total}</Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 5,
            color: theme.palette.text.secondary,
          }}
        >
          <Typography style={{ fontWeight: 800 }}>
            Total number of social accounts (per plan)
          </Typography>
          <Typography style={{ fontWeight: 800 }}>
            {plan.no_of_social_accounts_plan}
          </Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 5,
            color: theme.palette.text.secondary,
          }}
        >
          <Typography style={{ fontWeight: 800 }}>
            Upgraded social accounts (per plan)
          </Typography>
          <Typography style={{ fontWeight: 800 }}>
            {plan.no_of_social_accounts_addon}
          </Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 5,
            color: theme.palette.text.secondary,
          }}
        >
          <Typography style={{ fontWeight: 800 }}>
            Remaining social accounts
          </Typography>
          <Typography style={{ fontWeight: 800 }}>{remaining}</Typography>
        </Box>
      </Card>
    );
  };

  const getUsersView = () => {
    let plan = currentPlanDetails.plan_details.no_of_users;
    let total = plan.no_of_users_total;
    let currPlan = plan.no_of_users_current_used;
    let remaining = total - currPlan;
    if (remaining < 0) remaining = 0;
    return (
      <Card style={{ marginTop: 1, padding: 10, marginBottom: 5, borderRadius: 5, flex: 1 }}>
        <Typography style={{ fontWeight: 800, marginBottom: 10 }}>Users</Typography>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 5,
            color: theme.palette.text.secondary,
          }}
        >
          <Typography style={{ fontWeight: 800 }}>Total number of users</Typography>
          <Typography style={{ fontWeight: 800 }}>{total}</Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 5,
            color: theme.palette.text.secondary,
          }}
        >
          <Typography style={{ fontWeight: 800 }}>Total number of users (per plan)</Typography>
          <Typography style={{ fontWeight: 800 }}>{plan.no_of_users_plan}</Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 5,
            color: theme.palette.text.secondary,
          }}
        >
          <Typography style={{ fontWeight: 800 }}>Upgraded users (per plan)</Typography>
          <Typography style={{ fontWeight: 800 }}>{plan.no_of_users_addon}</Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 5,
            color: theme.palette.text.secondary,
          }}
        >
          <Typography style={{ fontWeight: 800 }}>Remaining users</Typography>
          <Typography style={{ fontWeight: 800 }}>{remaining}</Typography>
        </Box>
      </Card>
    );
  };

  const getBrandsView = () => {
    let plan = currentPlanDetails.plan_details.no_of_brands;
    let total = plan.no_of_brands_total;
    let currPlan = plan.no_of_brands_current_used;
    let remaining = currPlan - total;
    if (remaining < 0) remaining = 0;
    return (
      <Card style={{ marginTop: 1, marginBottom: 5, marginRight: 5, padding: 10, borderRadius: 5, flex: 1 }}>
        <Typography style={{ fontWeight: 800, marginBottom: 10 }}>Brands</Typography>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 5,
            color: theme.palette.text.secondary,
          }}
        >
          <Typography style={{ fontWeight: 800 }}>Total number of brands</Typography>
          <Typography style={{ fontWeight: 800 }}>{total}</Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 5,
            color: theme.palette.text.secondary,
          }}
        >
          <Typography style={{ fontWeight: 800 }}>Total number of brands (per plan)</Typography>
          <Typography style={{ fontWeight: 800 }}>{plan.no_of_brands_plan}</Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 5,
            color: theme.palette.text.secondary,
          }}
        >
          <Typography style={{ fontWeight: 800 }}>Upgraded brands (per plan)</Typography>
          <Typography style={{ fontWeight: 800 }}>{plan.no_of_brands_addon}</Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 5,
            color: theme.palette.text.secondary,
          }}
        >
          <Typography style={{ fontWeight: 800 }}>Remaining brands</Typography>
          <Typography style={{ fontWeight: 800 }}>{remaining}</Typography>
        </Box>
      </Card>
    );
  };

  const getScheduledPostView = () => {
    let plan = currentPlanDetails.plan_details.no_of_scheduled_posts;
    let total = plan.no_of_scheduled_posts_total;
    let currPlan = plan.no_of_scheduled_posts_current_used;
    let remaining = total - currPlan;
    if (remaining < 0) remaining = 0;
    return (
      <Card style={{ marginTop: 1, marginBottom: 5, padding: 10, borderRadius: 5, flex: 1 }}>
        <Typography style={{ fontWeight: 800, marginBottom: 10 }}>Scheduled Post</Typography>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 5,
            color: theme.palette.text.secondary,
          }}
        >
          <Typography style={{ fontWeight: 800 }}>Total number of scheduled post</Typography>
          <Typography style={{ fontWeight: 800 }}>{total}</Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 5,
            color: theme.palette.text.secondary,
          }}
        >
          <Typography style={{ fontWeight: 800 }}>Total number of scheduled post (per plan)</Typography>
          <Typography style={{ fontWeight: 800 }}>{plan.no_of_scheduled_posts_plan}</Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 5,
            color: theme.palette.text.secondary,
          }}
        >
          <Typography style={{ fontWeight: 800 }}>Upgraded scheduled post (per plan)</Typography>
          <Typography style={{ fontWeight: 800 }}>{plan.no_of_scheduled_posts_addon}</Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 5,
            color: theme.palette.text.secondary,
          }}
        >
          <Typography style={{ fontWeight: 800 }}>Remaining scheduled post</Typography>
          <Typography style={{ fontWeight: 800 }}>{remaining}</Typography>
        </Box>
      </Card>
    );
  };

  const getScheduleAheadView = () => {
    let plan = currentPlanDetails.plan_details.no_of_scheduled_days_ahead;
    let total = plan.no_of_scheduled_days_ahead_plan;
    return (
      <Card style={{ marginTop: 1, marginRight: 5, marginBottom: 5, padding: 10, borderRadius: 5, flex: 1 }}>
        <Typography style={{ fontWeight: 800, marginBottom: 10 }}>Schedule Ahead</Typography>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 5,
            color: theme.palette.text.secondary,
          }}
        >
          <Typography style={{ fontWeight: 800 }}>Total number of schedule ahead</Typography>
          <Typography style={{ fontWeight: 800 }}>{total}</Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 5,
            color: theme.palette.text.secondary,
          }}
        >
          <Typography style={{ fontWeight: 800 }}>Upgraded schedule ahead</Typography>
          <Typography style={{ fontWeight: 800 }}>{plan.no_of_scheduled_days_ahead_plan_id}</Typography>
        </Box>
      </Card>
    );
  };

  const getDraftsView = () => {
    let plan = currentPlanDetails.plan_details.no_of_draft_posts;
    let total = plan.no_of_scheduled_posts_total;
    return (
      <Card style={{ marginTop: 1, padding: 10, marginBottom: 5, borderRadius: 5, flex: 1 }}>
        <Typography style={{ fontWeight: 800, marginBottom: 10 }}>Drafts</Typography>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 5,
            color: theme.palette.text.secondary,
          }}
        >
          <Typography style={{ fontWeight: 800 }}>Total number of drafts</Typography>
          <Typography style={{ fontWeight: 800 }}>{total}</Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 5,
            color: theme.palette.text.secondary,
          }}
        >
          <Typography style={{ fontWeight: 800 }}>Upgraded drafts</Typography>
          <Typography style={{ fontWeight: 800 }}>{plan.no_of_scheduled_posts_addon}</Typography>
        </Box>
      </Card>
    );
  };

  const getStorageView = () => {
    let plan = currentPlanDetails.plan_details.no_of_storage;
    let total = plan.no_of_storage_total;
    return (
      <Card style={{ marginTop: 1, marginRight: 5, marginBottom: 5, padding: 10, borderRadius: 5, flex: 1 }}>
        <Typography style={{ fontWeight: 800, marginBottom: 10 }}>Storage</Typography>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 5,
            color: theme.palette.text.secondary,
          }}
        >
          <Typography style={{ fontWeight: 800 }}>Total gb per user</Typography>
          <Typography style={{ fontWeight: 800 }}>{total}</Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 5,
            color: theme.palette.text.secondary,
          }}
        >
          <Typography style={{ fontWeight: 800 }}>Upgraded gb per user</Typography>
          <Typography style={{ fontWeight: 800 }}>{plan.no_of_storage_addon}</Typography>
        </Box>
      </Card>
    );
  };

  const getWhiteLabelView = () => {
    let cName = currentPlanDetails.plan_details.cname;
    return (
      <Card style={{ marginTop: 1, padding: 10, marginBottom: 5, borderRadius: 5, flex: 1 }}>
        <Typography style={{ fontWeight: 800, marginBottom: 10 }}>White Label</Typography>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 5,
            color: theme.palette.text.secondary,
          }}
        >
          <Typography style={{ fontWeight: 800 }}>White Label available</Typography>
          <Typography style={{ fontWeight: 800 }}>{cName.has_cname ? 'Yes' : 'No'}</Typography>
        </Box>
      </Card>
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Card
            style={{
              maxHeight: 'calc(94vh - 100px)',
              minHeight: 'calc(94vh - 100px)',
              height: height,
            }}
          >
            <Card
              style={{
                maxHeight: 'calc(94vh - 300px)',
                minHeight: 'calc(94vh - 300px)',
                height: height,
                padding: 10,
                overflowY: 'auto',
                boxShadow: 'none'
              }}
            >
              <Grid container xs={12}>
                <Grid item xs={6} sx={{ display: 'flex' }}>
                  {getSocialPlatformsView()}
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex' }}>
                  {getUsersView()}
                </Grid>
              </Grid>

              <Grid container xs={12}>
                <Grid item xs={6} sx={{ display: 'flex' }}>
                  {getBrandsView()}
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex' }}>
                  {getScheduledPostView()}
                </Grid>
              </Grid>

              <Grid container xs={12}>
                <Grid item xs={6} sx={{ display: 'flex' }}>
                  {getScheduleAheadView()}
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex' }}>
                  {getDraftsView()}
                </Grid>
              </Grid>

              <Grid container xs={12}>
                <Grid item xs={6} sx={{ display: 'flex' }}>
                  {getStorageView()}
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex' }}>
                  {getWhiteLabelView()}
                </Grid>
              </Grid>

            </Card>
            <NextPlanBillDetails hasSubscription={hasSubscription} />
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card
            style={{
              maxHeight: 'calc(94vh - 100px)',
              minHeight: 'calc(94vh - 100px)',
              height: height,
            }}
          >
            <SubscriptionTabComponent />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
export default CurrentSubscriptionDetails;
