import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme, styled } from '@mui/material/styles';
import { Box, Stack, Drawer, IconButton, Typography } from '@mui/material';
import ChatAccount from './ChatAccount';
import ChatSearchResults from './ChatSearchResults';
import ChatContactSearch from './ChatContactSearch';
import ChatConversationList from './ChatConversationList';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import useResponsive from 'src/hooks/useResponsive';
import { chatModuleURL } from 'src/services/urlService';
import { useToaster } from 'src/utils/toaster/toasterContext';
import HttpServices from 'src/services/httpService';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import GroupIcon from '@mui/icons-material/Group';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ThreeDotLoader from 'src/utils/Loader/threeDotLoader.compontent';

const ToggleButtonStyle = styled((props) => <IconButton disableRipple {...props} />)(({ theme }) => ({
  left: 0,
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  top: theme.spacing(13),
  borderRadius: `0 12px 12px 0`,
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  // boxShadow: theme.customShadows.primary,
  '&:hover': {
    backgroundColor: theme.palette.primary.darker,
  },
}));

const SIDEBAR_WIDTH = 320;
const SIDEBAR_COLLAPSE_WIDTH = 96;

export default function ChatSidebar({ chatConversation, selectedConversation, handleConversation, isNextConversationLoading, handleNextConversation, userId, userPic, icon }) {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'sm');
  const [openSidebar, setOpenSidebar] = useState(true);
  const [isCollapse, setCollapse] = useState(false);
  const scrollRef = useRef(null);

  useEffect(() => {
    const scrollConversationToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
    scrollConversationToBottom();
  }, [chatConversation]);

  const handleOpenSidebar = () => {
    setOpenSidebar(true);
  };

  const handleCloseSidebar = () => {
    setOpenSidebar(false);
  };

  const handleToggleSidebar = () => {
    setOpenSidebar((prev) => !prev);
  };

  const handleMore = () => {
    handleNextConversation();
  }

  const renderContent = (
    <>
      {/* <Box sx={{ py: 2, px: 3 }}> */}
      {/* <Stack direction="row" alignItems="center" justifyContent="center"> */}
      {/* {!isCollapse && (
            <>
              <ChatAccount />
              <Box sx={{ flexGrow: 1 }} />
            </>
          )} */}

      {/* <IconButton onClick={handleToggleSidebar}>
            {openSidebar? <NavigateBeforeIcon  /> :<NavigateNextIcon  />}
          </IconButton>

          {!isCollapse && (
            <IconButton onClick={() => {
            }}>
               <ModeEditOutlineIcon sx={{height:"18px",width:"18px"}} />
            </IconButton>
          )} */}
      {/* </Stack> */}
      {/* </Box> */}
      <Scrollbar scrollableNodeProps={{ ref: scrollRef }}>
        <ChatConversationList
          chatConversation={chatConversation}
          isOpenSidebar={openSidebar}
          selectedConversation={selectedConversation}
          handleConversation={handleConversation}
          userPic={userPic}
          userId={userId}
          icon={icon}
        />
      </Scrollbar>
      <Stack display="flex"
        alignItems="center"
        justifyContent="center">
        {isNextConversationLoading ? (
          <ThreeDotLoader height="" />
        ) : (
          <Typography
            variant="body1"
            sx={{
              fontWeight: 700,
              textDecoration: 'underline',
              cursor: 'pointer',
              color: theme.palette.grey[500],
              mb:2
            }}
            onClick={handleMore}
          >
            More Messages
          </Typography>
        )}
      </Stack>
    </>
  );

  return (
    <>
      {!isDesktop && (
        <ToggleButtonStyle onClick={handleToggleSidebar}>
          <GroupIcon sx={{ height: "18px", width: "18px" }} />
        </ToggleButtonStyle>
      )}

      {isDesktop ? (
        <Drawer
          open={openSidebar}
          variant="persistent"
          sx={{
            width: SIDEBAR_WIDTH,
            transition: theme.transitions.create('width'),
            '& .MuiDrawer-paper': {
              position: 'static',
              width: SIDEBAR_WIDTH,
            },
            ...(isCollapse && {
              width: SIDEBAR_COLLAPSE_WIDTH,
              '& .MuiDrawer-paper': {
                width: SIDEBAR_COLLAPSE_WIDTH,
                position: 'static',
                transform: 'none !important',
                visibility: 'visible !important',
              },
            }),
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          ModalProps={{ keepMounted: true }}
          open={openSidebar}
          onClose={handleCloseSidebar}
          sx={{
            '& .MuiDrawer-paper': { width: SIDEBAR_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </>
  );
}
