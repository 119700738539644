import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PaymentSuccessComponent = () => {
    const navigate = useNavigate();

    const handleGoToDashboard = () => {
        navigate("/billing");
        window.location.reload();
    };

    return (
        <Box sx={{ textAlign: 'center', mt: 5 }}>
            <Typography variant="h4" gutterBottom>
                Payment Successful!
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
                Thank you for your payment. Your transaction has been successfully processed.
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
                You can now access your services or view your payment receipt.
            </Typography>
            <Box sx={{ mt: 4 }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleGoToDashboard}
                    sx={{ mr: 2 }}
                >
                    Go to Dashboard
                </Button>
            </Box>
        </Box>
    );
};

export default PaymentSuccessComponent;
