import { Box, Button, Checkbox, Divider, FormControlLabel, FormGroup, TableContainer, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import AddonPaymentComponent from 'src/components/stripe/addon-payment.component';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import { useToaster } from 'src/utils/toaster/toasterContext';

const UpgradeSubscriptionComponent = () => {
  const { showToast } = useToaster();

  const getInitialStruct = () => {
    return [
      {
        Value: 'no_of_social_accounts',
        DisplayName: 'Social Account',
        Quantity: 1,
        Price: 100,
      },
      {
        Value: 'no_of_users',
        DisplayName: 'Users',
        Quantity: 1,
        Price: 100,
      },
      {
        Value: 'no_of_brands',
        DisplayName: 'Brands',
        Quantity: 1,
        Price: 100,
      },
      {
        Value: 'no_of_scheduled_posts',
        DisplayName: 'Scheduled Posts',
        Quantity: 1,
        Price: 100,
      },
      {
        Value: 'no_of_scheduled_days_ahead',
        DisplayName: 'Scheduled Post Ahead',
        Quantity: 1,
        Price: 100,
      },
      {
        Value: 'no_of_drafts',
        DisplayName: 'Drafts',
        Quantity: 1,
        Price: 100,
      },
      {
        Value: 'no_of_storage',
        DisplayName: 'Storage',
        Quantity: 1,
        Price: 100,
      },
    ];
  };

  const UpgradeStruct = () => {
    return {
      no_of_social_accounts: 0,
      no_of_users: 0,
      no_of_brands: 0,
      no_of_scheduled_posts: 0,
      no_of_scheduled_days_ahead: 0,
      no_of_drafts: 0,
      no_of_storage: 0,
      has_c_name: false,
    };
  };

  // State variable
  const [addons, setAddons] = useState(getInitialStruct);
  const [upgradeItems, setUpgradeItems] = useState(UpgradeStruct);
  const [totalAmount, setTotalAmount] = useState(0);
  const [expiresOn, setPlanExpiresOn] = useState('');
  const [hasActiveSubscription, setActiveSubscription] = useState(false);
  const [isPaymentOn, setPaymentOn] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getAddonPrice();
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [upgradeItems, 500]);

  const handleInputChange = (event, addon) => {
    let value = event.target.value;
    let items = { ...upgradeItems };
    items[addon.Value] = value ? parseInt(value) : 0;
    setUpgradeItems(items);
  };

  const handleWhiteLabel = () => {
    let items = { ...upgradeItems };
    items["has_c_name"] = !items["has_c_name"];
    setUpgradeItems(items);
  }

  const failureCallback = (message) => {
    message && showToast(message);
  };

  const successCallback = (data, message) => {
    setTotalAmount(data.amount);
    setPlanExpiresOn(data.expire_date);
    setActiveSubscription(data.has_active_subscription);
  };

  const getAddonPrice = () => {
    let postProps = {
      url: userModuleURL.getAddonUpgradePrice,
      body: upgradeItems,
      successCallback: successCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };

  const handlePayment = () => {
    setPaymentOn(true);
  };

  return (
    <>
      <TableContainer
        sx={{
          maxHeight: 'calc(94vh - 280px)',
          minHeight: 'calc(94vh - 280px)',
        }}
      >
        {addons &&
          addons.map((addon) => {
            return (
              <Box key={addon.Value} sx={{ mb: 1, pl: 2, pr: 2 }}>
                <Typography sx={{ fontWeight: 500 }}>{addon.DisplayName}</Typography>
                <TextField
                  fullWidth
                  name={addon.Value}
                  placeholder={`Enter ${addon.DisplayName}`}
                  onChange={(event) => handleInputChange(event, addon)}
                  variant="outlined"
                  type="number"
                  sx={{
                    '& .MuiInputBase-input': {
                      padding: '10px 12px',
                    },
                  }}
                />
              </Box>
            );
          })}
        <Box key="white-label" sx={{ mb: 1, pl: 2, pr: 2 }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleWhiteLabel}
                />
              }
              label="White Label"

            />
          </FormGroup>
        </Box>
      </TableContainer>
      <Box sx={{ p: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Typography sx={{ fontWeight: 700, color: 'text.secondary' }}>Expires On:</Typography>
          <Typography sx={{ color: 'text.secondary' }}>{expiresOn ? CommonHelper.formatDateAndTime(expiresOn, 'date') : '-'}</Typography>
        </Box>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Typography sx={{ fontWeight: 700 }}>Total Amount:</Typography>
          <Typography>$ {totalAmount}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
          {hasActiveSubscription && (
            <Button variant="contained" onClick={() => handlePayment(true)} sx={{ mb: 0.5 }}>
              Pay
            </Button>
          )}
        </Box>
      </Box>
      {isPaymentOn && (
        <AddonPaymentComponent handleClose={handlePayment} addonUpgrade={upgradeItems} amount={totalAmount} />
      )}
    </>
  );
};
export default UpgradeSubscriptionComponent;
