import { Box, Card } from '@mui/material';
import { useEffect, useState } from 'react';
import HttpServices from 'src/services/httpService';
import { integrateModuleURL } from 'src/services/urlService';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import FailedPostGrid from './Grid/failedPostGrid.component';
const FailedPost = () => {
    const { showToast } = useToaster();
    const [loader, setLoader] = useState(true);
    const [failedPostList, setFailedPostList] = useState([]);
    const [order, setOrder] = useState();
    const [orderBy, setOrderBy] = useState();
    useEffect(() => {
        getFailedPost("scheduled_time", "asc");
    }, []);
    const postSuccessCallback = (data, message) => {
        message && showToast(message, "s");
        setFailedPostList([...data]);
        setLoader(false);
    }
    const failureCallback = (message) => {
        message && showToast(message, "e");
        setLoader(false);
    }
    const getFailedPost = (sortBy = "", sortOrder = "") => {
        setOrder(sortOrder)
        setOrderBy(sortBy)
        let currentDate = new Date();

        // One year before
        let fDate = new Date(currentDate);
        fDate.setFullYear(currentDate.getFullYear() - 1);
        fDate = fDate.toISOString();

        // One year after
        let tDate = new Date(currentDate);
        tDate.setFullYear(currentDate.getFullYear() + 1);
        tDate = tDate.toISOString();
        let getProps = {
            url: `${integrateModuleURL.getFailedPost}${fDate}&to=${tDate}&sort_by=${sortBy}&sort_order=${sortOrder}`,
            successCallback: postSuccessCallback,
            failureCallback
        }
        HttpServices.Get(getProps);
    }
    const handleReCallFailedPost = (sortBy = "", sortOrder = "") => {
        setLoader(true);
        getFailedPost(sortBy, sortOrder);
    }
    return (
        <Box>
            {loader ?
                <Spinner />
                :
                <Card>
                    <FailedPostGrid failedPostList={failedPostList} orderBy={orderBy} order={order} handleReCallFailedPost={handleReCallFailedPost} />
                </Card>
            }
        </Box>
    );
}
export default FailedPost;