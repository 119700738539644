import { useRef, useState, useEffect} from 'react';
import {
  Box,
  Typography,
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Paper,
  Stack,
  useMediaQuery,
  useTheme,
  Divider,
} from '@mui/material';
import FBcomment from '../../../../assets/comment.svg';
import FBlike from '../../../../assets/fblike.svg';
import FBshare from '../../../../assets/fbshare.svg';
import PublicIcon from '@mui/icons-material/Public';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { styled } from '@mui/material/styles';

const PlayButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '48px',
  height: '48px',
  backgroundColor: '#fff',
  borderRadius: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
}));

const GradientOverlay = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '48px',
  height: '48px',
});

const FacebookPostVideoPreview = ({ data }) => {
  const theme = useTheme();
  const respwidth = useMediaQuery(theme.breakpoints.up('xl'));
  const ConnectedProfiles = data.connected_profile_details;
  const videoRef = useRef(null);
  const [isVideoPlaying, setVideoPlaying] = useState(false);
  const [showControls, setShowControls] = useState(false);

  useEffect(() => {
    return () => {
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    };
  }, []);

  const handlePlayButtonClick = () => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {});
      setShowControls(true);
    }
  };

  const handleVideoPlay = () => {
    setVideoPlaying(true);
  };
  const handleVideoPause = () => {
    setVideoPlaying(false);
  };

  const videoShow = () => {
    return (
      <Box sx={{ position: 'relative', width: 'auto', height: '250px' }}>
        <video
          preload="auto"
          crossOrigin="anonymous"
          src={data.media_url}
          width="100%"
          height="100%"
          style={{ objectFit: 'cover', position: 'absolute', borderRadius: '10px' }}
          ref={videoRef}
          controls={showControls}
          onPlay={handleVideoPlay}
          onPause={handleVideoPause}
        />
        {!isVideoPlaying && (
          <PlayButton onClick={handlePlayButtonClick}>
            <PlayArrowIcon sx={{ fontSize: 40 }} />
          </PlayButton>
        )}
        <GradientOverlay />
      </Box>
    );
  };

  const ProfileTime = () => {
    return (
      <>
        <Stack direction="row" justifyContent="flex-start" alignItems="flex-start">
          <Typography variant="span">Just Now</Typography>
          <PublicIcon sx={{ fontSize: 15, mt: '0.3rem', ml: '0.2rem' }} />
        </Stack>
      </>
    );
  };

  return (
    <>
      <Stack container justifyContent="center" alignItems="center">
        <Card
          sx={{
            maxWidth: 500,
            minWidth: respwidth ? 400 : null,
            boxShadow: 'none',
            border: 'solid 2px',
            borderColor: theme.palette.preview.postcard.color,
          }}
        >
          <CardHeader
            avatar={
              <Avatar
                src={
                  ConnectedProfiles.connected_profile_image
                    ? ConnectedProfiles.connected_profile_image
                    : ConnectedProfiles.connected_profile_name || ConnectedProfiles.connected_profile_id
                }
                alt={
                  ConnectedProfiles.connected_profile_name
                    ? ConnectedProfiles.connected_profile_name
                    : ConnectedProfiles.connected_profile_id
                }
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  height: 40,
                  width: 40,
                  bgcolor: theme.palette.primary.main,
                }}
                style={{ color: 'white' }}
              />
            }
            title={ConnectedProfiles?.connected_profile_name}
            sx={{ fontSize: { xl: 15, lg: 13, md: 11, sm: 9, xs: 7 } }}
            subheader={<ProfileTime />}
            action={<MoreVertIcon sx={{ mt: 1 }} />}
          />
          <CardContent>
            <Typography
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                overflow: 'hidden',
                WebkitBoxDecorationBreak: 'clone',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
              }}
            >
              {data.description}
            </Typography>
          </CardContent>
          <CardMedia sx={{ pl: 2, pr: 2 }}>{videoShow()}</CardMedia>
          <CardActions disableSpacing sx={{ display: 'block' }}>
            <Divider />
            <Stack direction="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Paper sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                <img
                  src={FBlike}
                  style={{ height: 'auto', width: '100%', maxWidth: '18px', maxHeight: '18px', marginLeft: '1rem' }}
                />
                <Typography
                  color={'text.secondary'}
                  sx={{ fontWeight: 700, fontSize: { xl: 15, lg: 13, md: 11, sm: 9, xs: 7 }, marginLeft: '0.5rem' }}
                >
                  Like{' '}
                </Typography>
              </Paper>
              <Paper sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={FBcomment}
                  style={{ height: 'auto', width: '100%', maxWidth: '20px', maxHeight: '20px', marginLeft: '0.5rem' }}
                />
                <Typography
                  sx={{
                    fontWeight: 700,
                    color: 'text.secondary',
                    fontSize: { xl: 15, lg: 13, md: 11, sm: 9, xs: 7 },
                    marginLeft: '0.5rem',
                  }}
                >
                  Comment{' '}
                </Typography>
              </Paper>
              <Paper sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={FBshare}
                  style={{ height: 'auto', width: '100%', maxWidth: '20px', maxHeight: '20px', marginRight: '0.5rem' }}
                />
                <Typography
                  sx={{
                    fontWeight: 700,
                    color: 'text.secondary',
                    fontSize: { xl: 15, lg: 13, md: 11, sm: 9, xs: 7 },
                    marginRight: '0.5rem',
                  }}
                >
                  Share{' '}
                </Typography>
              </Paper>
            </Stack>
          </CardActions>
        </Card>
      </Stack>
    </>
  );
};

export default FacebookPostVideoPreview;
