import AccountBoxSharpIcon from '@mui/icons-material/AccountBoxSharp';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { Box, Tab, Tabs, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { capitalCase } from 'change-case';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import EllapsedPost from './ellapsed-approval/ellapsed-post.component';
import PendingApproval from './pending-approval/pending-approval.component';
import RejectedApproval from './rejected-post/rejected-approval.component';

const Approval = () => {
    const [currentTab, setCurrentTab] = useState('pending approval');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const ACCOUNT_TABS = [
        {
            value: 'pending approval',
            icon: <AccountBoxSharpIcon sx={{ width: "20px", height: "20px" }} />,
            component: <PendingApproval />,
        },
        {
            value: 'rejected approval',
            icon: <VpnKeyIcon sx={{ width: "20px", height: "20px" }} />,
            component: <RejectedApproval />,
        },
        {
            value: 'Ellapsed Requests',
            icon: <VpnKeyIcon sx={{ width: "20px", height: "20px" }} />,
            component: <EllapsedPost />,
        }
    ];
    return (
        <>
            <Helmet>
                <title>Approval</title>
            </Helmet>
            <Box>
                <Tabs
                    value={currentTab}
                    scrollButtons="auto"
                    variant="scrollable"
                    sx={{ gap: '0.5rem', minHeight: '37px' }}
                    allowScrollButtonsMobile
                    onChange={(e, value) => setCurrentTab(value)}
                >
                    {ACCOUNT_TABS.map((tab) => {
                        return <Tab
                            disableRipple
                            key={tab.value}
                            label={isMobile ? "" : capitalCase(tab.value)}
                            icon={tab.icon}
                            value={tab.value}
                            sx={{ flexDirection: 'row', gap: '0.25rem', padding: 0, minHeight: '10px', mr: '0.75rem', fontWeight: 600 }}
                        />
                    })}
                </Tabs>

            </Box>
            <Box sx={{ mb: 2 }} />
            {ACCOUNT_TABS.map((tab) => {
                const isMatched = tab.value === currentTab;
                return isMatched && <Box key={tab.value}>{tab.component}</Box>;
            })}
        </>
    )
}
export default Approval;