import { useEffect } from "react";
import { Config } from "src/config";
import HttpServices from "src/services/httpService";
import { integrateModuleURL } from "src/services/urlService";
import { useState } from "react";
import { useToaster } from "src/utils/toaster/toasterContext";
import GoogleMyBusinessAuthorizationModal from "./google-my-business-authorization-modal";
import GBIcon from '../../../assets/google-my-business-logo.svg';
import { styled } from '@mui/material/styles';
import {
    Avatar, Badge, Box, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemAvatar,
    ListItemButton, ListItemText, Typography
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { StyledComponent } from 'src/components/styled-components/styledComponent';

const GoogleMyBusinessIntegration = (props) => {
    const [isAuthorizationModalOpen, setAuthorizationModal] = useState(true);
    const [isGoogleMyBusinessAccountModalOpen, setGoogleMyBusinessAccountModal] = useState(false);
    const [isNoAccountForIntegrate, setNoAccountIntegrated] = useState(false);
    const [pages, setPages] = useState([]);
    const [profileId, setProfileId] = useState("");
    const [selectedPages, setSelectedPages] = useState([]);
    const { showToast } = useToaster();
    const [height, setHeight] = useState(window.innerHeight - 600);
    let intervalId = null;

    useEffect(() => {
        intervalId = setInterval(() => {
            checkAccessTokenReceivedFromGoogleMyBusiness();
        }, Config.Common.socialAccountsIntegrationInterval);
        return () => {
            clearInterval(intervalId);
        };
    }, [])
    const getGoogleMyBusinessOauthToken = () => {
        window.open(
            `${Config.GoogleMyBusiness.authorizeUrl}?client_id=${Config.GoogleMyBusiness.clientId}&scope=${Config.GoogleMyBusiness.scope}&prompt=consent&access_type=offline&include_granted_scopes=true&state=state_parameter_passthrough_value&redirect_uri=${Config.GoogleMyBusiness.redirectUri}&response_type=code`, "_blank"
        );
    }

    const checkAccessTokenReceivedFromGoogleMyBusiness = () => {
        var token = localStorage.getItem("googleMyBusiness_token");
        var error = localStorage.getItem("googleMyBusiness_error");
        if (token) {
            localStorage.removeItem("googleMyBusiness_token");
            clearInterval(intervalId);
            sendAccessTokenToServer(token);
        }
        else if (error) {
            localStorage.removeItem("googleMyBusiness_error");
            clearInterval(intervalId);
            showToast(error, 'e');
        }
    };
    const checkAccountIsIntegrated = (account) => {
        let isChecked = false;
        if (account.is_integrated)
            isChecked = true;
        else {
            let selectedPageList = [...selectedPages];
            if (selectedPageList && selectedPageList.length > 0) {
                let index = selectedPageList.findIndex(a => a.id === account.id);
                if (index >= 0)
                    isChecked = true;
            }
        }
        return isChecked;
    }
    const handlePages = (pageId) => {
        let selectedPageList = [...selectedPages];
        let index = selectedPageList.findIndex((a) => a.id === pageId);
        if (index >= 0) selectedPageList.splice(index, 1);
        else {
            let selectedPage = pages.find((a) => a.id === pageId);
            selectedPageList.push(selectedPage);
        }
        setSelectedPages(selectedPageList);
    };
    const SmallAvatar = styled(Avatar)(({ theme }) => ({
        width: 22,
        height: 22,
        overflow: 'hidden',
    }));
    const myBusinessSuccessCallback = (data, message) => {
        showToast(message, 's');
        handleClose();
    }

    const handleSubmitPages = () => {
        let linkedInForm = {
            pages: selectedPages,
            id: profileId
        };
        let postProps = {
            url: integrateModuleURL.integrateGoogleMyBusinessAccessToken,
            body: linkedInForm,
            successCallback: myBusinessSuccessCallback,
            failureCallback: googleMyBusinessFailureCallback
        }
        HttpServices.Post(postProps);
    };
    const googleMyBusinessSuccessCallback = (data, message) => {
        if (data.pages && data.pages.length > 0) {
            let isNoAccountForIntegrate = !data.pages.some(a => !a.is_integrated);
            setNoAccountIntegrated(isNoAccountForIntegrate);
        }
            setAuthorizationModal(false);
            setGoogleMyBusinessAccountModal(true);
            setProfileId(data.id);
            setPages(data.pages);
    }
    const googleMyBusinessFailureCallback = (message) => {
        showToast(message, 'e');
        handleClose();
    }
    const sendAccessTokenToServer = (code) => {
        let googleMyBusinessForm = {
            code
        };
        let postProps = {
            url: integrateModuleURL.sendGoogleMyBusinessAccessToken,
            body: googleMyBusinessForm,
            successCallback: googleMyBusinessSuccessCallback,
            failureCallback: googleMyBusinessFailureCallback
        }
        HttpServices.Post(postProps);
    };

    const handleClose = () => {
        props.handleClose();
    }

    return (
        <>
        {isAuthorizationModalOpen &&
        <GoogleMyBusinessAuthorizationModal
            handleConnectGoogleMyBusiness={getGoogleMyBusinessOauthToken}
            handleClose={handleClose}
        />}
          {isGoogleMyBusinessAccountModalOpen ? (
                <Dialog onClose={handleClose} open={isGoogleMyBusinessAccountModalOpen} fullWidth>
                    <DialogTitle>Google My Business Pages</DialogTitle>
                    <DialogContent dividers>
                        <div className="l2s-space-between">
                        <Box sx={{
                                            maxHeight: height, height: height - 60
                                        }}>
                                {pages && pages.length > 0 ?
                                    <>
                                        <List>
                                            {pages.map((a) => {
                                                return (
                                                    <ListItem
                                                        secondaryAction={
                                                            <Checkbox
                                                                edge="end"
                                                                value={a.id}
                                                                checked={checkAccountIsIntegrated(a)}
                                                                onClick={isNoAccountForIntegrate ? null : () => { handlePages(a.id) }}
                                                                disabled={a.is_integrated || a.verified === "FAILED"}
                                                            />
                                                        }
                                                        disablePadding
                                                    >
                                                        <ListItemButton onClick={isNoAccountForIntegrate ? null : () => { handlePages(a.id) }}  disabled={a.is_integrated ||a.verified === "FAILED"}>
                                                            <ListItemAvatar>
                                                                <Badge
                                                                    overlap="circular"
                                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                    badgeContent={
                                                                        <SmallAvatar alt="gbusiness" src={GBIcon}  variant="rounded"/>
                                                                    }
                                                                >
                                                                    <Avatar
                                                                        src={a.profile_image ? a.profile_image : a.name || a.id}
                                                                        alt={a.name ? a.name : a.id}
                                                                        style={{ color: '#ffffff' }}
                                                                    />
                                                                </Badge>
                                                            </ListItemAvatar>
                                                            <ListItemText primary={a.name} secondary={a.description} />
                                                          {a.verified === "COMPLETED" ? <Chip label="Verified" color="primary"/> : <Chip label="Unverified" color="error"/>}
                                                        </ListItemButton>
                                                    </ListItem>
                                                )
                                            })}
                                        </List>
                                        {isNoAccountForIntegrate && <Typography
                                            sx={{
                                                color: 'red',
                                                fontSize: '12px',
                                                marginTop: '10px'
                                            }}
                                        >
                                            All of your accounts are integrated
                                        </Typography>}
                                    </>
                                    :
                                    <Typography sx={{
                                        color: 'red',
                                        margin: '10px'
                                    }}>
                                        There is no Google My Business Pages to integrate
                                    </Typography>
                                }
                            </Box>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button color='error' variant="outlined" onClick={handleClose}>Cancel</Button>
                        {pages && pages.length > 0 && !isNoAccountForIntegrate &&
                            <LoadingButton variant="contained" color='primary' disabled={selectedPages.length === 0} autoFocus onClick={handleSubmitPages} >
                                Submit
                            </LoadingButton>}
                    </DialogActions>
                </Dialog>
            ) : null}
        </>
    );
};
export default GoogleMyBusinessIntegration;