import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Avatar from '@mui/material/Avatar';
import { CommonHelper } from 'src/utils/commonHelper';
import { SessionStorage } from 'src/utils/storage/session-storage';

export default function BrandSelect({ handleChangeBrand }) {

  const initialLoad = CommonHelper.GetBrandInfoFromLocalStorage();
  const activeBrandId = CommonHelper.GetCurrentBrandId();

  // State variable
  const [brandList, setBrandList] = useState([]);
  const [currentBrandId, setCurrentBrand] = useState(activeBrandId);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const brand_details = initialLoad.brand_details;

  useEffect(() => {
    getAllBrandsFromLocalStorage();
  }, []);

  const getAllBrandsFromLocalStorage = () => {
    let brand_details = initialLoad.brand_details;
    if (brand_details?.length > 0) {
      setBrandList(brand_details);
      let sBrand = brand_details.find((a) => a.brand_id === currentBrandId);
      setSelectedBrand(sBrand);
    }
  };

  const handleBrand = (event) => {
    let value = event.target.value;
    setCurrentBrand(value);
    handleChangeBrand(value);
    let sBrand = brandList.find((a) => a.brand_id === value);
    setSelectedBrand(sBrand);
  };

  return (
    <FormControl sx={{ boxShadow: 'none', border: 'none' }}>
      <Select
        id="brand-id"
        value={selectedBrand}
        onChange={handleBrand}
        renderValue={(selected) => {
          return (
            <Avatar
              src={selected?.brand_image ? selected?.brand_image : selected?.brand_name}
              alt={selected?.brand_image ? selected?.brand_image : selected?.brand_name}
            />
          );
        }}
        sx={{
          paddingLeft: 0,
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '& .MuiSelect-select': {
            paddingLeft: 0,
          },
        }}
      >
        {brand_details.map((brand) => (
          <MenuItem key={brand.brand_id} value={brand.brand_id}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Avatar
                src={brand?.brand_image ? brand?.brand_image : brand?.brand_name}
                alt={brand?.brand_image ? brand?.brand_image : brand?.brand_name}
              />
              {brand?.brand_name ? brand?.brand_name : brand?.brand_id}
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
