import { Box, Card, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import HttpServices from "src/services/httpService";
import { postsModuleURL } from "src/services/urlService";
import { useToaster } from 'src/utils/toaster/toasterContext';
import FacebookPreview from "./FacebookPreview/facebook-preview.component";
import InstagramPreview from "./InstagramPreview/instagram-preview.component";
import TwitterPreview from "./TwitterPreview/twitter-preview.component";
import YoutubePreview from "./YoutubePreview/youtube-preview.component";
import LinkedinPreview from "./LinkedinPreview/linkedin-preview.component";
import GoogleMyBusinessPreview from "./GoogleMyBusinessPreview/gbusiness-preview.component";
import Spinner from "src/utils/Loader/spinner";

const PostPreview = ({ postDetails }) => {
    const { showToast } = useToaster();
    const [scheduleEventInfo, setScheduleEventInfo] = useState([]);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        getScheduledPost();
    }, []);

    const getScheduledPost = () => {
        let getProps = {
            url: `${postsModuleURL.scheduledEventInfo}${postDetails.data.id}`,
            successCallback: postSuccessCallback,
            failureCallback
        }
        HttpServices.Get(getProps);
    }

    const postSuccessCallback = (data, message) => {
        message && showToast(message, "s");
        setScheduleEventInfo(data);
        setLoader(false);
    }
    const failureCallback = (message) => {
        message && showToast(message, "e");
        setLoader(false);
    }


    return (
        <Box>
            {loader ?
                <Spinner />
                :
                <Card style={{"marginLeft":"1rem"}} >
                    <Typography variant="h5" gutterBottom sx={{ p:1 }}>
                        Preview Event
                    </Typography>
                    {scheduleEventInfo?.scheduled_event_requests_data && <Card>
                        <FacebookPreview postDetails={scheduleEventInfo.scheduled_event_requests_data.facebook_requests_data}></FacebookPreview>
                        <InstagramPreview postDetails={scheduleEventInfo.scheduled_event_requests_data.instagram_requests_data}></InstagramPreview>
                        <TwitterPreview postDetails={scheduleEventInfo.scheduled_event_requests_data.twitter_requests_data}></TwitterPreview>
                        <YoutubePreview postDetails={scheduleEventInfo.scheduled_event_requests_data.youtube_requests_data}></YoutubePreview>
                        <LinkedinPreview postDetails={scheduleEventInfo.scheduled_event_requests_data.linkedin_requests_data}></LinkedinPreview>
                        <GoogleMyBusinessPreview postDetails={scheduleEventInfo.scheduled_event_requests_data.gbusiness_requests_data}></GoogleMyBusinessPreview>
                    </Card>}
                </Card>
            }
        </Box>
    )
}

export default PostPreview;