const PostType = {
    POST: 'post',
    REELS: 'reels',
    SHORTS: 'shorts',
    STORY: 'story',
    VIDEO: 'video',
    DIRECT_POST: 'directpost',
    UPLOAD_TO_INBOX: 'uploadtoinbox',
    STANDARD: 'STANDARD',
    OFFER: 'OFFER',
    EVENT: 'EVENT'
}

const MediaType = {
    VIDEO: 'video',
    IMAGE:'image',
    DOCUMENT:'document'
}
const ServiceName = {
    FACEBOOK: "FACEBOOK",
    INSTAGRAM: "INSTAGRAM",
    TWITTER: "TWITTER",
    YOUTUBE: "YOUTUBE",
    LINKEDIN: "LINKEDIN",
    TIKTOK: "TIKTOK",
    GBUSINESS: "GBUSINESS"
}
const DisplayName = {
    FACEBOOK: "FACEBOOK",
    INSTAGRAM: "INSTAGRAM",
    TWITTER: "TWITTER",
    YOUTUBE: "YOUTUBE",
    LINKEDIN: "LINKEDIN",
    TIKTOK: "TIKTOK",
    GBUSINESS: "GOOGLE MY BUSINESS"
}
const RecentPostServiceName = {
    FACEBOOK: "facebook_service",
    INSTAGRAM: "instagram_service",
    TWITTER: "twitter_service",
    YOUTUBE: "youtube_service",
    LINKEDIN: "linkedIn_service",
    TIKTOK: "tiktok_service",
    GBUSINESS:"gbusiness_service"
}

const DashboardProfileServiceName = {
    FACEBOOK: "dashboard_facebook_profile_stats",
    INSTAGRAM: "dashboard_instagram_profile_stats",
    TWITTER: "dashboard_twitter_profile_stats",
    YOUTUBE: "dashboard_youtube_profile_stats",
    LINKEDIN: "dashboard_linkedin_profile_stats",
    TIKTOK: "dashboard_tiktok_profile_stats",
    GBUSINESS:"dashboard_gbusiness_profile_stats"
}
const ProfileType = {
    PAGE: "PAGE",
    GROUP: "GROUP",
    PROFILE: "PROFILE"
}
const SupportedFileFormat = [
    "video/mp4",
    "image/jpeg",
    "image/png"
]
const TikTokPrivacyLevel = {
    PUBLIC_TO_EVERYONE: 'PUBLIC_TO_EVERYONE',
    MUTUAL_FOLLOW_FRIENDS: 'MUTUAL_FOLLOW_FRIENDS',
    FOLLOWER_OF_CREATOR: 'FOLLOWER_OF_CREATOR',
    SELF_ONLY: 'SELF_ONLY'
}
const YoutubePrivacyLevel = {
    PUBLIC: 'public',
    PRIVATE: 'private',
    UNLISTED: 'unlisted'
}
const TikTokOptions = {
    DISABLE_DUET: 'disable_duet',
    DISABLE_COMMENT: 'disable_comment',
    DISABLE_SWITCH: 'disable_switch',
    BRAND: 'your_brand',
    BRAND_CONTENT: 'brand_content'
}
const ServiceConfigurations = {
    FACEBOOK: {
        REELS: {
            MINSECONDS: 3, // 3 Seconds
            MAXSECONDS: 60, // 60 Seconds
            ASPECTRATIO: "9:16"
        },
        POST: {
            MAXSECONDS: 14400, // 4 Hours
            ASPECTRATIO: "9:16"
        },
        STORY: {
            MINSECONDS: 3, // 3 Seconds
            MAXSECONDS: 60, // 60 Seconds
            ASPECTRATIO: "9:16"
        }
    },
    INSTAGRAM: {
        REELS: {
            MAXSECONDS: 90, // 90 Seconds
            ASPECTRATIO: "9:16"
        },
        POST: {
            MAXSECONDS: 43200, // 12 Hours
            VIDEOLENGTH: 60,
        },
        STORY: {
            MINSECONDS: 3, // 3 Seconds
            MAXSECONDS: 60, // 60 Seconds
            ASPECTRATIO: "9:16"
        }
    },
    YOUTUBE: {
        SHORTS: {
            MAXSECONDS: 60, // 60 Seconds
            ASPECTRATIO: "9:16"
        },
        POST: {
            MAXSECONDS: 43200, // 12 Hours
        }
    },
    TWITTER: {
        POST: {
            MAXIMAGECOUNT: 4,
        },
    },
    LINKEDIN: {
        POST: {
            MAXIMAGECOUNT: 20,
            MAXSECONDS: 900,
        }
    },
    GBUSINESS: {
        POST: {
            MAXIMAGECOUNT: 1,
        }
    },
    TIKTOK: {
        POST: {
            IMAGE: {
                MAXIMAGECOUNT: 35
            },
            VIDEO: {
                MAXWIDTH: 360,
                MAXHEIGHT: 360,
                MINSECONDS: 1,
                MAXSECONDS: 600
            }
        }
    }
}
export const PostUtil = {
    PostType,
    ServiceName,
    DisplayName,
    RecentPostServiceName,
    DashboardProfileServiceName,
    SupportedFileFormat,
    ServiceConfigurations,
    ProfileType,
    TikTokPrivacyLevel,
    YoutubePrivacyLevel,
    MediaType,
    TikTokOptions
}