import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
// import './stripe.scss';
import CheckoutForm from './checkoutForm';
import HttpServices from 'src/services/httpService';
import { paymentModuleUrl } from 'src/services/urlService';
import { Config } from 'src/config';
import StripePaymentComponent from './stripe-payment.component';
import PaymentDialogComponent from 'src/pages/payment/payment-dialog.component';

const stripePromise = loadStripe(Config.Stripe.clientId);

const PlanPaymentComponent = ({ planId, amount ,upgradeItems,handlePaymentDialog}) => {
  const [clientSecret, setClientSecret] = useState('');
  useEffect(() => {
    createPaymentLink();
  }, []);
  const successCallback = (data, message) => {
    setClientSecret(data.payment_intent);
  }
  const createPaymentLink = () => {
    let paymentForm = {
      new_plan_id: planId,
      add_ons: upgradeItems,
    };
    let postProps = {
      url: paymentModuleUrl.createPayment,
      body: paymentForm,
      successCallback
    }
    HttpServices.Post(postProps);
  };
  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };
  const checkoutFormProps = { amount: amount }
  return (
    // <div className="stripe-payment-c">
    //   {clientSecret && (
    //     <Elements options={options} stripe={stripePromise}>
    //       <CheckoutForm {...checkoutFormProps} />
    //     </Elements>
    //   )}
    // </div>
    clientSecret && <PaymentDialogComponent clientSecret={clientSecret} amount={amount} handleClose={handlePaymentDialog}/>
  );
}
export default PlanPaymentComponent;