import React, { useState } from 'react';
import { Box, Checkbox, IconButton, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Upload as UploadIcon, CheckCircleOutline as CheckCircleOutlineIcon, PlayCircleOutline as PlayIcon } from '@mui/icons-material';
import DeleteIcon from '../../assets/deletecomment.svg';
import HttpServices from 'src/services/httpService';

const MediaFileComponent = ({
  mediaData,
  handleDelete,
  handleUpload,
  handleSelectFile,
  selectedFiles,
  isFromNewPost = false,
}) => {

  const width = isFromNewPost ? 100 : 200;
  const height = isFromNewPost ? 100 : 200;

  // State Variable
  const [isLoading, setLoading] = useState(false);
  const [playingMedia, setPlayingMedia] = useState(null);

  const handleUploadToServer = () => {
    setLoading(true);
    handleUpload();
    setLoading(false);
  };

  const isSelected = (media) => selectedFiles.some((file) => file.media_url === media.media_url);

  const handleMediaClick = (e, media) => {
    e.stopPropagation();
    handleSelectFile(e, media);
  };

  const handlePlay = async (media) => {
    if (media.file_type === 'VIDEO') {
      if (!media["media_blob"]) {
        const media_url = await HttpServices.GetImageFromAPI(media.media_url);
        media["media_blob"] = media_url;
      }
      setPlayingMedia(media);
    }
  }

  return (
    <>
      {mediaData?.length > 0 &&
        mediaData.map((media) => {
          const selected = isSelected(media);
          let isPlayingMedia = playingMedia && media.media_url === playingMedia.media_url;
          console.log(isPlayingMedia);
          return (
            <Box
              key={media.media_url}
              sx={{
                position: 'relative',
                width: width,
                height: height,
                overflow: 'hidden',
                borderRadius: 2,
                boxShadow: 1,
                cursor: 'pointer',
              }}
              onClick={(e) => { selected ? handleMediaClick(e, media) : handlePlay(media) }}
            >
              {media.file_type === 'IMAGE' ? (
                <img
                  src={media.thumbnail_url}
                  alt={media.file_name.slice(16)}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    filter: selected ? 'brightness(0.7)' : 'none',
                    cursor: 'pointer'
                  }}
                />
              ) : (
                isPlayingMedia && !selected ?
                  <video
                    src={media?.media_blob}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      filter: selected ? 'brightness(0.7)' : 'none',
                      cursor: 'pointer'
                    }}
                    autoPlay
                    loop
                    muted
                  />
                  :
                  <img
                    src={media.thumbnail_url}
                    alt={media.file_name.slice(16)}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      filter: selected ? 'brightness(0.7)' : 'none',
                      cursor: 'pointer'
                    }}
                  />
              )}
              <Typography
                variant="body2"
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  width: '100%',
                  bgcolor: 'background.primary',
                  color: 'white',
                  textAlign: 'center',
                  p: 0.5,
                  display: 'block',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  cursor: 'pointer'
                }}
              >
                {media.file_name.slice(16)}
              </Typography>
              {selected ? (
                <CheckCircleOutlineIcon
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: isFromNewPost ? '3rem' : '5rem',
                    color: 'primary.main',
                  }}
                />
              )
                :
                (
                  media.file_type === 'VIDEO' && !isPlayingMedia &&
                  <PlayIcon
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      fontSize: isFromNewPost ? '3rem' : '5rem',
                      color: 'primary.main'
                    }}
                  />
                )
              }
              <Box
                className="overlay"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  transition: 'opacity 0.3s',
                }}
              >
                <Checkbox
                  checked={selected}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: isFromNewPost ? 20 : 30,
                      color: 'text.disabled',
                      display: selected ? 'none' : 'block',
                    },
                  }}
                  onChange={(e) => handleSelectFile(e, media)}
                  onClick={(e) => e.stopPropagation()}
                />
                {typeof handleDelete === 'function' && (
                  <IconButton
                    onClick={() => handleDelete(media)}
                    sx={{ color: 'white', display: selected ? 'none' : 'block' }}
                  >
                    <img
                      src={DeleteIcon}
                      style={{ width: isFromNewPost ? 20 : 30, maxWidth: '22px', maxHeight: '22px' }}
                    />
                  </IconButton>
                )}
              </Box>
            </Box>
          );
        })}
      {/* {!isFromNewPost && typeof handleUpload === 'function' && (
        <LoadingButton
          variant="contained"
          loading={isLoading}
          onClick={handleUploadToServer}
          endIcon={<UploadIcon sx={{ width: '20px', height: '20px' }} />}
        >
          Upload
        </LoadingButton>
      )} */}
    </>
  );
};
export default MediaFileComponent;
