import { styled } from '@mui/material/styles';
import { Stack, Button, Tooltip, Typography, IconButton, ToggleButton } from '@mui/material';
import useResponsive from 'src/hooks/useResponsive';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import ViewDayIcon from '@mui/icons-material/ViewDay';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const VIEW_OPTIONS = [
  { value: 'dayGridMonth', label: 'Month', icon: 'ic:round-view-module' },
  // { value: 'timeGridWeek', label: 'Week', icon: 'ic:round-view-week' },
  // { value: 'timeGridDay', label: 'Day', icon: 'ic:round-view-day' },
  { value: 'listWeek', label: 'Agenda', icon: 'ic:round-view-agenda' },
];

const getViewIcon = (iconName) => {
  switch (iconName) {
    case 'ic:round-view-module':
      return <ViewModuleIcon sx={{ width: "20px", height: "20px"}}/>
    case 'ic:round-view-week':
      return <ViewWeekIcon sx={{ width: "20px", height: "20px"}}/>
    case 'ic:round-view-day':
      return <ViewDayIcon sx={{ width: "20px", height: "20px"}}/>
    case 'ic:round-view-agenda':
      return <ViewAgendaIcon sx={{ width: "20px", height: "20px"}}/>
    default:
      return null;
  }
};
const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2.5),
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

export default function CalendarToolbar({ date, view, onToday, onNextDate, onPrevDate, onChangeView }) {
  const isDesktop = useResponsive('up', 'sm');

  return (
    <RootStyle>
      {isDesktop && (
        <Stack direction="row" spacing={0.5}>
          {VIEW_OPTIONS.map((viewOption) => (
            <Tooltip key={viewOption.value} title={viewOption.label}>
              <ToggleButton
                value={view}
                selected={viewOption.value === view}
                onChange={() => onChangeView(viewOption.value)}
                sx={{ width: 32, height: 32, padding: 0, border: 0 }}
              >
                {getViewIcon(viewOption.icon)}
              </ToggleButton>
            </Tooltip>
          ))}
        </Stack>
      )}

      <Stack direction="row" alignItems="center">
        <IconButton onClick={onPrevDate}>
        <ChevronLeftIcon/>
        </IconButton>

        <Typography variant="h6" sx={{margin:'0 !important' }}>{date.toString()}</Typography>

        <IconButton onClick={onNextDate}>
        <ChevronRightIcon/>
        </IconButton>
      </Stack>

      {isDesktop && (
        <Button size="small" color="error" variant="contained" onClick={onToday}>
          Today
        </Button>
      )}
    </RootStyle>
  );
}
