import { LoadingButton } from '@mui/lab';
import { Box, Card, Container, Typography } from '@mui/material';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import Header from 'src/layouts/dashboard/header/header.component';
import { CommonHelper } from 'src/utils/commonHelper';
import { useToaster } from 'src/utils/toaster/toasterContext';
import NobrandIcon from '../../assets/icons/no-brnd-org.svg';
import CreateBrand from './createBrand.component';

const NoBrand = () => {

  const { showToast } = useToaster();
  const navigate = useNavigate();

  const activeBrandId = CommonHelper.GetCurrentBrandId();
  
  if (activeBrandId) {
    window.location.href = window.location.origin;
  }

  const isOrgUser = CommonHelper.GetBrandInfoFromLocalStorage("is_org_user");

  // State Variables
  const [isLeaveOrgLoading, setLeaveOrgLoader] = useState(false);
  const [isDeleteOrgLoading, setDeleteOrgLoader] = useState(false);

  const leaveOrgSuccessCallback = (data, message) => {
    message && showToast(message, 's');
    navigate('/no-organization');
  };

  const failureCallback = (message) => {
    message && showToast(message, 'e');
    setLeaveOrgLoader(false);
    setDeleteOrgLoader(false);
  };

  const handleLeaveOrganization = () => {
    setLeaveOrgLoader(true);
    CommonHelper.LeaveOrganization(leaveOrgSuccessCallback, failureCallback);
  };

  const handleDeleteOrganization = () => {
    setDeleteOrgLoader(true);
    CommonHelper.DeleteOrganization(leaveOrgSuccessCallback, failureCallback);
  }

  return (
    <>
      <Helmet>
        <title> No Brand </title>
      </Helmet>
      <Header headerName={''} />
      <Container>
        <div style={{
          display: 'flex',
          minHeight: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 10
        }}
        >
          <Container>
            <Box sx={{ maxWidth: 680, mx: 'auto' }}>
              <Card sx={{ py: 3, px: 3, textAlign: 'center' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <img src={NobrandIcon} style={{ height: 300 }} />
                </Box>
                <Typography variant="h3" paragraph>
                  Currently, you are not associated with any of the Brand
                </Typography>
                <Typography sx={{ color: 'text.secondary', mb: 3 }}>Please contact Admin or super Admin.</Typography>
                {isOrgUser && (
                  <>
                    <CreateBrand />
                    <LoadingButton
                      variant="contained"
                      size="large"
                      fullWidth
                      style={{ marginBottom: 20 }}
                      loading={isDeleteOrgLoading}
                      onClick={handleDeleteOrganization}
                    >
                      Delete Organization
                    </LoadingButton>
                  </>
                )}
                <LoadingButton
                  variant="contained"
                  size="large"
                  fullWidth
                  loading={isLeaveOrgLoading}
                  onClick={handleLeaveOrganization}
                >
                  Leave Organization
                </LoadingButton>
              </Card>
            </Box>
          </Container>
        </div>
      </Container>
    </>
  );
};
export default NoBrand;
