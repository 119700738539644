import { HelmetProvider } from 'react-helmet-async';
import InitApplication from './init-application';

const App = () => {
  return (
    <HelmetProvider>
      <InitApplication />
    </HelmetProvider>
  );
};
export default App;
