import { Popover, MenuItem } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import ReplayIcon from '@mui/icons-material/Replay';

const IntegratedActionPopup = ({ popoverElement, handleCloseMenu,handleUpdateAccount, handleDeletePost,handleReAuthenticate }) => {
  return (
    <Popover
      open={Boolean(popoverElement)}
      anchorEl={popoverElement}
      onClose={handleCloseMenu}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: { p: 1, width: "auto", '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 } },
      }}
    >
      <MenuItem sx={{ color: 'error.main' }} onClick={handleDeletePost}>
        <DeleteForeverIcon sx={{ mr: 1 }} /> Delete Account
      </MenuItem>
      <MenuItem sx={{ color: 'warning.dark' }} onClick={handleUpdateAccount}>
        <UpgradeIcon sx={{ mr: 1 }} />Update Account
      </MenuItem>
      <MenuItem sx={{ color: 'primary.main' }} onClick={handleReAuthenticate}>
        <ReplayIcon sx={{ mr: 1 }} />Re-Authenticate Account
      </MenuItem>
    </Popover>
  );
};

export default IntegratedActionPopup;
