import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Collapse, Hidden, IconButton, TableBody, ListItemText, CircularProgress } from '@mui/material';
import { useState } from 'react';
import { StyledComponent } from 'src/components/styled-components/styledComponent';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import { PostDetailsHelper } from 'src/utils/postDetailsHelper';
import { useToaster } from 'src/utils/toaster/toasterContext';
import FailedPostGridAction from './FailedPostGridAction.component';
import FailedPostNestedGrid from './NestedGrid/FailedPostNestedGrid.component';
import Spinner from 'src/utils/Loader/spinner';
import PostPreview from 'src/pages/scheduledPost/Preview/post-preview.component';
import { useRightSidebar } from 'src/layouts/right-side-bar/rightSidebarProvider';

const FailedPostGridBody = ({ failedPostList, page, rowsPerPage, handleReCallFailedPost }) => {
  const [smallLoader, setSmallLoader] = useState(false);
  const [openRows, setOpenRows] = useState({});
  const [selectedPopOverPost, setPopOverPost] = useState(null);
  const [popoverElement, setPopoverElement] = useState(null);
  const { showToast } = useToaster();
  const handleOpenScheduledPost = (row, open = false) => {
    let data = {
      content: postView,
      data: row,
    };
    showRightSidebar(data, '500px');
  };

  const postView = (row) => {
    let data = {
      id: row,
    };
    let postDetails = {
      data: data,
    };
    return <PostPreview postDetails={postDetails}></PostPreview>;
  };

  const { showRightSidebar } = useRightSidebar();

  const handleToggleNestedTable = (id) => {
    setOpenRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleOpenMenu = (event, row) => {
    setPopOverPost(row);
    setPopoverElement(event.currentTarget);
  };

  const handleToggleScheduledPost = (scheduledPost) => {
    let bodyProps = {
      schedule_post_id: scheduledPost.id,
    };
    let postProps = {
      url: scheduledPost.is_enabled ? userModuleURL.disableScheduledPost : userModuleURL.enableScheduledPost,
      body: bodyProps,
      successCallback: toggleScheduledPostSuccessCallback,
      failureCallback: toggleScheduledPostFailureCallback,
    };
    HttpServices.Post(postProps);
  };

  const toggleScheduledPostSuccessCallback = (data, message) => {
    showToast(message, 's');
    handleReCallFailedPost();
  };

  const toggleScheduledPostFailureCallback = (message) => {
    showToast(message, 'e');
    handleReCallFailedPost();
  };

  return (
    <>
      <TableBody>
        {failedPostList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
          const isOpen = !!openRows[row.id];
          return (
            <>
              <StyledComponent.StyledTableRow
                onClick={() => {
                  handleOpenScheduledPost(row.id, true);
                }}
              >
                <StyledComponent.StyledTableCell>
                  <StyledComponent.HoverContent
                    onClick={(event) => event.stopPropagation()}
                  >
                    <IconButton aria-label="expand row" size="small" onClick={() => handleToggleNestedTable(row.id)}>
                      {isOpen ?
                        <KeyboardArrowUpIcon />
                        :
                        <KeyboardArrowDownIcon />
                      }
                    </IconButton>
                  </StyledComponent.HoverContent>
                </StyledComponent.StyledTableCell>
                <StyledComponent.StyledTableCell>
                  {CommonHelper.getTableDateAndTime(row.scheduled_time)}
                </StyledComponent.StyledTableCell>
                <Hidden only={['xs']}>
                  <StyledComponent.StyledTableCell>
                    <ListItemText
                      sx={{
                        m: 0,
                        '.MuiListItemText-primary': {
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontSize: '0.875rem',
                        },
                        '.MuiListItemText-secondary': {
                          color: '#637381',
                          fontSize: '0.75rem',
                          textTransform: 'lowercase',
                        },
                      }}
                      primary={CommonHelper.formatDateAndTime(row.created_time, 'date')}
                      secondary={CommonHelper.formatDateAndTime(row.created_time, 'time')}
                    />
                  </StyledComponent.StyledTableCell>
                </Hidden>
                <Hidden only={['xs']}>
                  <StyledComponent.StyledTableCell>
                    <ListItemText
                      sx={{
                        m: 0,
                        '.MuiListItemText-primary': {
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontSize: '0.875rem',
                        },
                        '.MuiListItemText-secondary': {
                          color: '#637381',
                          fontSize: '0.75rem',
                          textTransform: 'lowercase',
                        },
                      }}
                      primary={CommonHelper.formatDateAndTime(row.updated_at, 'date')}
                      secondary={CommonHelper.formatDateAndTime(row.updated_at, 'time')}
                    />
                  </StyledComponent.StyledTableCell>
                </Hidden>
                <StyledComponent.StyledTableCell>
                  {PostDetailsHelper.GetPostContent(row.description)}
                </StyledComponent.StyledTableCell>
                <Hidden only={['xs']}>
                  <StyledComponent.StyledTableCell>
                    {PostDetailsHelper.getPostMedias(row.media_url)}
                  </StyledComponent.StyledTableCell>
                </Hidden>
                <StyledComponent.StyledTableCell>
                  {PostDetailsHelper.getProfile(row.created_by.name, row.created_by.email, row.created_by.profile_pic)}
                </StyledComponent.StyledTableCell>
                <StyledComponent.StyledTableCell>
                  {PostDetailsHelper.getProfile(
                    row.approved_by.name,
                    row.approved_by.email,
                    row.approved_by.profile_pic
                  )}
                </StyledComponent.StyledTableCell>
                <Hidden only={['xs']}>
                  <StyledComponent.StyledTableCell>
                    {PostDetailsHelper.getSocialMediaViewWithTooltip(row.connected_profile_details)}
                  </StyledComponent.StyledTableCell>
                </Hidden>
                <StyledComponent.StyledTableCell align='center' onClick={(event) => event.stopPropagation()}>
                  <FailedPostGridAction failedPost={row} handleReCallFailedPost={handleReCallFailedPost} />
                </StyledComponent.StyledTableCell>
              </StyledComponent.StyledTableRow>
              {isOpen && (
                <StyledComponent.StyledTableRow>
                  <StyledComponent.StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                      <FailedPostNestedGrid
                        setSmallLoader={setSmallLoader}
                        failedPostId={row.id}
                      ></FailedPostNestedGrid>
                    </Collapse>
                  </StyledComponent.StyledTableCell>
                </StyledComponent.StyledTableRow>
              )}
            </>
          );
        })}
      </TableBody>
    </>
  );
};

export default FailedPostGridBody;
