import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Drawer, List, Stack, Typography,IconButton } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
import { NavConfig } from 'src/navConfig';
import { CommonHelper } from 'src/utils/commonHelper';
import Logo from '../Logo';
import { NavItemRoot } from '../nav-section/vertical/NavItem';
import Scrollbar from '../scrollbar/Scrollbar';
import CollapseButton from './CollapseButton';

const MenuBarComponent = ({ isMainMenuBarView, menuItems, backButtonClick }) => {
    const hasPublishPostPermission = CommonHelper.CheckForPermissionAvailableForUser(CommonHelper.PermissionList.PUBLISHED_POSTS);
    let appName = CommonHelper.GetBrandInfoFromLocalStorage("org_name");
    let appDescription = CommonHelper.GetBrandInfoFromLocalStorage("org_description");
    appName = appName ? appName : "Brand2Social";
    appDescription = appDescription ? appDescription : "Social Media Scheduler";
    const theme = useTheme();
    const navigate = useNavigate();
    const { isCollapse, onToggleCollapse } = useCollapseDrawer();

    const handleSidebar = (menuItem) => {
        navigate(menuItem.path);
    }
    const HighlightButton = styled(Button)(({ }) => ({
        margin: '5px 15px 0px',
        borderRadius: '4px',
        boxShadow: 'none',
        fontWeight: 600,
        fontSize: '16px',
        gap: '0.5rem',
        minWidth: '0px',
        span: {
            margin: '0px',
        },
    }));
    const AddIconStyle = styled(AddIcon)(({ }) => ({
        fontSize: '21px !important'
    }));
    const StyledTypography = styled(Typography)(({ theme }) => ({
        textDecoration: 'underline',
        textTransform: 'none',
        fontWeight:800,
        fontSize: '18px !important',
        color: theme.palette.primary.main,
        cursor: 'pointer', 
    }));
    const CustomIconButton = styled(IconButton)(({ theme }) => ({
        color: `${theme.palette.primary.main}`,
        backgroundColor: `${theme.palette.secondary.main}`,
        width: '30px', 
        height: '30px',
        padding: 0,
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        borderRadius: '4px',
    }));
    return (
        <>
            <Drawer
                open
                variant="persistent"
                PaperProps={{
                    sx: {
                        position: 'relative',
                        width: NavConfig.NAVBAR.DASHBOARD_WIDTH,
                        borderRightStyle: 'dashed',
                        bgcolor: 'background.paper.',
                        transition: (theme) =>
                            theme.transitions.create('width', {
                                duration: theme.transitions.duration.standard,
                            }),
                        ...(isCollapse && {
                            width: NavConfig.NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
                        }),
                    },
                }}
            >
                <Scrollbar
                    sx={{
                        height: 1,
                        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
                        ...(isCollapse && {
                            scrollbarWidth: 'none'
                        })
                    }}
                >
                    <Stack
                        spacing={3}
                        sx={{
                            pt: 1, pb: 1, px: 2.5, flexShrink: 0,
                            ...(isCollapse && { alignItems: 'center' }),
                            display: 'flex', justifyContent: 'center'
                        }}
                    >
                        <Stack direction="row" alignItems="center" gap="1rem">
                            {!isCollapse && <>
                                {isMainMenuBarView && <><Logo />
                                    <Box>
                                        <Typography sx={{ fontWeight: 800, ml: 0, fontSize: '18px', color: theme.palette.primary.main }}>{appName}</Typography>
                                        <Typography sx={{ fontWeight: 600, ml: 0, fontSize: '12px', color: theme.palette.grey[500] }}>{appDescription}</Typography>
                                    </Box></>}
                            </>}
                            <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={isCollapse} />
                        </Stack>
                    </Stack>
                    {!isCollapse ? <>
                        {!isMainMenuBarView ? (
                            <Stack direction="row" alignItems="center" spacing={0.8} sx={{ml:'1rem',mb:'0.5rem'}}>
                            <CustomIconButton onClick={backButtonClick}>
                                <ArrowBackIcon />
                            </CustomIconButton>
                            <StyledTypography variant="body2" onClick={backButtonClick}>
                                Back
                            </StyledTypography>
                        </Stack>
                        ) : (
                            hasPublishPostPermission && (
                                <HighlightButton
                                    variant="contained"
                                    startIcon={<AddIconStyle />}
                                    onClick={() => { navigate('/new-post') }}
                                >
                                    Compose Post
                                </HighlightButton>
                            )
                        )}

                    </>
                        : !isMainMenuBarView ?
                            <HighlightButton variant="contained"
                                startIcon={<ArrowBackIcon />}
                                onClick={backButtonClick} >
                            </HighlightButton>
                            : (hasPublishPostPermission && (<HighlightButton variant="contained"
                                startIcon={<AddIconStyle />}
                                onClick={() => { navigate('/new-post') }} >
                            </HighlightButton>))
                    }
                    <Box>
                        <List disablePadding sx={{
                            px: 2, ...(isCollapse && {
                                padding: '4px',
                            })
                        }}>
                            <NavItemRoot menuItems={menuItems} isCollapse={isCollapse} handleSidebar={handleSidebar} />
                        </List>
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                </Scrollbar>
            </Drawer>
        </>
    );
}
export default MenuBarComponent;