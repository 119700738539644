import { Box, Grid, Stack, Card, Tab } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import ImageDraggable from 'src/components/image/image-draggable';
import { CommonHelper } from 'src/utils/commonHelper';
import { useToaster } from 'src/utils/toaster/toasterContext';
import { NewPostStyledComponent } from './newPostStyled.component';
import PostDescription from './post-description.component';
import PostUploadComponent from './post-upload.component';
import ConnectedMediaComponent from './connected-media.component';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import MobileViewPostConditionComponent from './mobile-view-post-condition/post-condition.component';
import PostPreviewComponent from './post-preview.component';
import { ErrorOutline as ErrorOutlineIcon, Send as SendIcon, ScheduleSend as ScheduleSendIcon } from '@mui/icons-material';
import { LoadingButton } from "@mui/lab";
import { FormControlLabel, TextField, Typography, IconButton } from "@mui/material";
import { LocalizationProvider, MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { SVGComponent } from "src/components/svg/svg-component";
import useCollapseDrawer from "src/hooks/useCollapseDrawer";
import { useTheme, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Savedraft from '../../assets/icons/save-draft.svg';

const MobileNewPostComponent = ({
    configuredSocialMedia,
    selectedSocialMedias,
    handleNetworks,
    handleAddAllDescription,
    handleMediaFiles,
    newPostForm,
    updateRequestData,
    handleConditionErrors,
    googleMyBusinessConditionRef,
    tiktokConditionRef,
    linkedInConditionRef,
    youtubeConditionRef,
    twitterConditionRef,
    instagramConditionRef,
    facebookConditionRef,
    previewRef,
    tabValue,
    setTabValue,
    isSubmittingDraft,
    handleSaveDraft,
    scheduleDateTime,
    handleScheduleDate,
    whenToPost,
    PostOption,
    handlePostTypeChange,
    handleFormsubmit,
    isSubmitting
}) => {
    const { showToast } = useToaster();

    //State Variables
    useEffect(() => {
        handleUpdateNewFormData();
    }, [tabValue]);

    const [isUploadMediaPopupOpen, setUploadMediaPopup] = useState(false);
    const [fileUploadprogress, setFileUploadprogress] = useState();
    const [height, setHeight] = useState(window.innerHeight - 200);
    const theme = useTheme();
    const customTheme = createTheme({
        breakpoints: {
            values: {
                mdCustom: 1100,
            },
        },
    });

    const isDesktop = useMediaQuery(customTheme.breakpoints.up('mdCustom'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleUploadMediaPopup = (show = false) => {
        setUploadMediaPopup(show);
    };
    const handleUploadMedia = (files) => {
        let mFiles = [...newPostForm.media_data];
        mFiles = mFiles.concat(files);
        setUploadMediaPopup(false);
        handleMediaFiles(mFiles);
    };
    const privateFileUploadSuccessCallback = (data, message, uploadFiles = []) => {
        const mFiles = [...data?.media_datas, ...uploadFiles];
        message && showToast(message, 's');
        handleUploadMedia(mFiles);
        handleUploadMediaPopup(false);
    };

    const fileUploadSuccessCallback = (data, message, privateFiles) => {
        if (privateFiles.length > 0) {
            const uploadFilesProps = {
                privateFiles,
                successCallback: (privateData, message) => privateFileUploadSuccessCallback(privateData, message, data),
                failureCallback,
            };
            CommonHelper.UploadPrivateMediaFilesToServer({ ...uploadFilesProps });
        } else {
            message && showToast(message, 's');
            handleUploadMedia(data);
            handleUploadMediaPopup(false);
        }
    };

    const failureCallback = (message) => {
        showToast(message, 'e');
    };
    const handleUploadMediaToServer = (files, privateFiles) => {
        if (files.length == 0 && privateFiles.length > 0) {
            const uploadFilesProps = {
                privateFiles,
                successCallback: (data, message) => privateFileUploadSuccessCallback(data, message),
                failureCallback,
            };
            CommonHelper.UploadPrivateMediaFilesToServer({ ...uploadFilesProps });
        }
        else {
            let uploadFilesProps = {
                files,
                setFileUploadprogress,
                successCallback: (data, message) => { fileUploadSuccessCallback(data, message, privateFiles) },
                failureCallback,
            };
            CommonHelper.UploadMediaFilesToServer({ ...uploadFilesProps });
        }
    };
    const handleReOrderFiles = (oldIndex, newIndex) => {
        let files = newPostForm.media_data;
        let mediaFiles = CommonHelper.ReArrangeArray(files, oldIndex, newIndex);
        handleMediaFiles(mediaFiles);
    };
    const handleRemoveFiles = (mediaUrl) => {
        let files = [...newPostForm.media_data];
        let index = files.findIndex((a) => a.media_url === mediaUrl);
        if (index >= 0) files.splice(index, 1);
        handleMediaFiles(files);
    };

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };
    const handleUpdateNewFormData = () => {
        let newFormData = { ...newPostForm };
        let facebookRequestData = newFormData.scheduled_event_requests_data.facebook_requests_data;
        let instagramRequestData = newFormData.scheduled_event_requests_data.instagram_requests_data;
        let youtubeRequestData = newFormData.scheduled_event_requests_data.youtube_requests_data;
        let twitterRequestData = newFormData.scheduled_event_requests_data.twitter_requests_data;
        let linkedinRequestData = newFormData.scheduled_event_requests_data.linkedin_requests_data;
        let gbusinessRequestData = newFormData.scheduled_event_requests_data.gbusiness_requests_data;
        if (facebookRequestData?.length > 0) {
            if (facebookConditionRef && facebookConditionRef.current) {
                facebookConditionRef.current.handleRequestData(facebookRequestData);
            }
        }
        if (instagramRequestData?.length > 0) {
            if (instagramConditionRef && instagramConditionRef.current) {
                instagramConditionRef.current.handleRequestData(instagramRequestData);
            }
        }
        if (youtubeRequestData?.length > 0) {
            if (youtubeConditionRef && youtubeConditionRef.current) {
                youtubeConditionRef.current.handleRequestData(youtubeRequestData);
            }
        }
        if (twitterRequestData?.length > 0) {
            if (twitterConditionRef && twitterConditionRef.current) {
                twitterConditionRef.current.handleRequestData(twitterRequestData);
            }
        }
        if (linkedinRequestData?.length > 0) {
            if (linkedInConditionRef && linkedInConditionRef.current) {
                linkedInConditionRef.current.handleRequestData(linkedinRequestData);
            }
        }
        if (gbusinessRequestData?.length > 0) {
            if (googleMyBusinessConditionRef && googleMyBusinessConditionRef.current) {
                googleMyBusinessConditionRef.current.handleRequestData(gbusinessRequestData);
            }
        }

        //Preview Ref
        if (previewRef && previewRef.current) {
            let fData = JSON.parse(JSON.stringify(newFormData));
            previewRef.current.handlePreviewData(fData);
        }
    };

    const submitButtonView = () => {
        let submitButtonName = PostOption.PostNow.Label;
        switch (whenToPost) {
            case PostOption.PostNow.Name:
                submitButtonName = PostOption.PostNow.Label;
                break;
            case PostOption.SchedulePost.Name:
                submitButtonName = PostOption.SchedulePost.Label;
                break;
            default:
                break;
        }
        return submitButtonName;
    };

    return (
        <>
            <Box sx={{ boxShadow: 'none', height: 'calc(100vh - 150px)', overflow: 'auto', background:'white', p:1, pt:2 }}>
                <Grid container>
                    <Grid item xs={12} alignItems="center">
                        <Stack direction="row" justifyContent="space-between" spacing={2}>
                            <NewPostStyledComponent.LabelStyle>Profile</NewPostStyledComponent.LabelStyle>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <ConnectedMediaComponent
                            configuredSocialMedia={configuredSocialMedia}
                            selectedSocialMedias={selectedSocialMedias}
                            handleNetworks={handleNetworks}
                        />
                    </Grid>
                </Grid>
                <PostDescription
                    handleChangeDescription={handleAddAllDescription}
                    valueDescription={newPostForm.description}
                    handleUploadMediaPopup={handleUploadMediaPopup}
                    showInputProps={true}
                />
                {newPostForm.media_data?.length > 0 && (
                    <Box>
                        <ImageDraggable
                            mediaFiles={newPostForm.media_data}
                            handleRemoveFiles={handleRemoveFiles}
                            handleReOrderFiles={handleReOrderFiles}
                        />
                    </Box>
                )}
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                        <TabList onChange={handleChangeTab}>
                            <Tab iconPosition="start" label="Conditions" value={1} />
                            <Tab iconPosition="start" label="Preview" value={2} />
                        </TabList>
                    </Box>
                    <TabPanel value={1} sx={{ padding:0 , paddingTop:2 }}>
                        <MobileViewPostConditionComponent
                            updateRequestData={updateRequestData}
                            handleConditionErrors={handleConditionErrors}
                            googleMyBusinessConditionRef={googleMyBusinessConditionRef}
                            tiktokConditionRef={tiktokConditionRef}
                            linkedInConditionRef={linkedInConditionRef}
                            youtubeConditionRef={youtubeConditionRef}
                            twitterConditionRef={twitterConditionRef}
                            instagramConditionRef={instagramConditionRef}
                            facebookConditionRef={facebookConditionRef}
                        />
                    </TabPanel>
                    <TabPanel value={2} sx={{ padding:0 , paddingTop:2 }}>
                        <PostPreviewComponent ref={previewRef} />
                    </TabPanel>
                </TabContext>
                <Box style={{ width: '100%', marginTop: '0.8rem', marginBottom:'0.3rem' }} >
                    <Stack display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ height: '3rem' }}>
                        <IconButton
                            onClick={handleSaveDraft}
                        >
                            <img src={Savedraft} alt="Savedraft_Icon"
                                width={25}
                                height={25} />
                        </IconButton>
                        <Stack display="flex" flexDirection="row" style={{ alignItems: 'center' }}>
                            {whenToPost === PostOption.SchedulePost.Name && (
                                <LocalizationProvider dateAdapter={AdapterDayjs} size="small" sx={{ pb: 2 }}>
                                    <MobileDateTimePicker
                                        placeholder="Select Time"
                                        value={dayjs(scheduleDateTime)}
                                        onChange={handleScheduleDate}
                                        disabled={whenToPost !== PostOption.SchedulePost.Name}
                                        renderInput={(props) => <TextField {...props} size="small"
                                        />}
                                    />
                                </LocalizationProvider>
                            )}
                            <FormControlLabel
                                control={
                                    <NewPostStyledComponent.SwitchStyle
                                        sx={{ mr: whenToPost !== PostOption.SchedulePost.Name && 1, ml: 2 }}
                                        size="small"
                                        checked={whenToPost === PostOption.SchedulePost.Name}
                                        onChange={handlePostTypeChange}
                                    />
                                }
                                label={whenToPost !== PostOption.SchedulePost.Name && <Typography variant="body1" color="black">Is Scheduled Post</Typography>}
                            />
                            <IconButton
                                onClick={handleFormsubmit}
                            >{whenToPost === PostOption.SchedulePost.Name ? <ScheduleSendIcon style={{ color: theme.palette.primary.main }} /> : <SendIcon style={{ color: theme.palette.primary.main }} />}

                            </IconButton>
                        </Stack>
                    </Stack>
                </Box>
            </Box>
            {/* Popup Place */}
            {isUploadMediaPopupOpen && (
                <PostUploadComponent handleClose={handleUploadMediaPopup} fileUploadprogress={fileUploadprogress} handleUpload={handleUploadMediaToServer} />
            )}
        </>
    );
};
export default MobileNewPostComponent;