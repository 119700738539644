import { Box, Card, Grid, Stack } from '@mui/material';
import { useState } from 'react';
import ImageDraggable from 'src/components/image/image-draggable';
import { CommonHelper } from 'src/utils/commonHelper';
import { useToaster } from 'src/utils/toaster/toasterContext';
import { NewPostStyledComponent } from './newPostStyled.component';
import PostDescription from './post-description.component';
import PostUploadComponent from './post-upload.component';
import ConnectedMediaComponent from './connected-media.component';

const PostContentComponent = ({
  configuredSocialMedia,
  selectedSocialMedias,
  handleNetworks,
  handleAddAllDescription,
  handleMediaFiles,
  newPostForm,
}) => {
  const { showToast } = useToaster();

  //State Variables
  const [isUploadMediaPopupOpen, setUploadMediaPopup] = useState(false);
  const [fileUploadprogress, setFileUploadprogress] = useState();
  const [height, setHeight] = useState(window.innerHeight - 200);

  const handleUploadMediaPopup = (show = false) => {
    setUploadMediaPopup(show);
  };
  const handleUploadMedia = (files) => {
    let mFiles = [...newPostForm.media_data];
    mFiles = mFiles.concat(files);
    setUploadMediaPopup(false);
    handleMediaFiles(mFiles);
  };
  const privateFileUploadSuccessCallback = (data, message, uploadFiles = []) => {
    const mFiles = [...data?.media_datas, ...uploadFiles];
    message && showToast(message, 's');
    handleUploadMedia(mFiles);
    handleUploadMediaPopup(false);
  };

  const fileUploadSuccessCallback = (data, message, privateFiles) => {
    if (privateFiles.length > 0) {
      const uploadFilesProps = {
        privateFiles,
        successCallback: (privateData, message) => privateFileUploadSuccessCallback(privateData, message, data),
        failureCallback,
      };
      CommonHelper.UploadPrivateMediaFilesToServer({ ...uploadFilesProps });
    } else {
      message && showToast(message, 's');
      handleUploadMedia(data);
      handleUploadMediaPopup(false);
    }
  };

  const failureCallback = (message) => {
    showToast(message, 'e');
  };
  const handleUploadMediaToServer = (files, privateFiles) => {
    if (files.length == 0 && privateFiles.length > 0) {
      const uploadFilesProps = {
        privateFiles,
        successCallback: (data, message) => privateFileUploadSuccessCallback(data, message),
        failureCallback,
      };
      CommonHelper.UploadPrivateMediaFilesToServer({ ...uploadFilesProps });
    }
    else {
      let uploadFilesProps = {
        files,
        setFileUploadprogress,
        successCallback: (data, message) => { fileUploadSuccessCallback(data, message, privateFiles) },
        failureCallback,
      };
      CommonHelper.UploadMediaFilesToServer({ ...uploadFilesProps });
    }
  };
  const handleReOrderFiles = (oldIndex, newIndex) => {
    let files = newPostForm.media_data;
    let mediaFiles = CommonHelper.ReArrangeArray(files, oldIndex, newIndex);
    handleMediaFiles(mediaFiles);
  };
  const handleRemoveFiles = (mediaUrl) => {
    let files = [...newPostForm.media_data];
    let index = files.findIndex((a) => a.media_url === mediaUrl);
    if (index >= 0) files.splice(index, 1);
    handleMediaFiles(files);
  };

  return (
    <>
      <Card sx={{ p: 3, height: 'calc(100vh - 190px)', overflow: 'auto' }}>
        <Grid container>
          <Grid item xs={12} alignItems="center">
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <NewPostStyledComponent.LabelStyle>Profile</NewPostStyledComponent.LabelStyle>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <ConnectedMediaComponent
              configuredSocialMedia={configuredSocialMedia}
              selectedSocialMedias={selectedSocialMedias}
              handleNetworks={handleNetworks}
            />
          </Grid>
        </Grid>
        <PostDescription
          handleChangeDescription={handleAddAllDescription}
          valueDescription={newPostForm.description}
          handleUploadMediaPopup={handleUploadMediaPopup}
          showInputProps={true}
        />
        {newPostForm.media_data?.length > 0 && (
          <Box>
            <ImageDraggable
              mediaFiles={newPostForm.media_data}
              handleRemoveFiles={handleRemoveFiles}
              handleReOrderFiles={handleReOrderFiles}
            />
          </Box>
        )}
      </Card>
      {/* Popup Place */}
      {isUploadMediaPopupOpen && (
        <PostUploadComponent handleClose={handleUploadMediaPopup} fileUploadprogress={fileUploadprogress} handleUpload={handleUploadMediaToServer} />
      )}
    </>
  );
};
export default PostContentComponent;
