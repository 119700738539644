import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { MenuItem, Popover } from '@mui/material';

const ManageUserAction = ({
  popoverElement,
  handleCloseMenu,
  handleRemoveUserFromOrg,
  handleRemoveFromBrand,
  handleOpenConfirm,
  selectedPopOverPost
}) => {
  return (
    <Popover
      open={Boolean(popoverElement)}
      anchorEl={popoverElement}
      onClose={handleCloseMenu}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: { p: 1, width: 'auto', '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 } },
      }}
    >
      {!selectedPopOverPost?.is_org_user && <MenuItem sx={{ color: 'warning.main' }} onClick={() => handleOpenConfirm("brand")}>
        <RemoveCircleIcon sx={{ mr: 1 }} /> Remove from Brand
      </MenuItem>
      }

      <MenuItem
        sx={{ color: 'error.main' }}
        onClick={() => {
          handleOpenConfirm("org")
        }}
      >
        <DeleteForeverIcon sx={{ mr: 1 }} />Remove User From Org
      </MenuItem>

    </Popover>
  );
};
export default ManageUserAction;
