import { BrandingWatermark as BrandingWatermarkIcon, Business as BusinessIcon } from '@mui/icons-material';
import { Box, Tab, Tabs, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { CommonHelper } from 'src/utils/commonHelper';
import BillingComponent from './billing.component';
import PlanInfoComponent from './plan-info.component';
import { Helmet } from 'react-helmet-async';
import { capitalCase } from 'change-case';
import AppSumoUpgradeComponent from './appsumo-upgrade.component';

const BillingInfoComponent = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const userPermissions = CommonHelper.GetBrandInfoFromLocalStorage("permissions");
    const isAppSumoUser = CommonHelper.GetBrandInfoFromLocalStorage('is_appsumo_user');

    const [currentTab, setCurrentTab] = useState('Subscription Info');

    let ACCOUNT_TABS = [];

    if (isAppSumoUser) {
        ACCOUNT_TABS = [
            {
                value: 'Subscription Info',
                icon: <BusinessIcon sx={{ width: "20px", height: "20px" }} />,
                component: <BillingComponent />,
                permissions: [CommonHelper.PermissionList.ORG_ADMIN]
            },
            {
                value: 'AppSumo Subscription',
                icon: <BrandingWatermarkIcon sx={{ width: "20px", height: "20px" }} />,
                component: <AppSumoUpgradeComponent />,
                permissions: [CommonHelper.PermissionList.ORG_ADMIN]
            },
        ];
    }
    else {
        ACCOUNT_TABS = [
            {
                value: 'Subscription Info',
                icon: <BusinessIcon sx={{ width: "20px", height: "20px" }} />,
                component: <BillingComponent />,
                permissions: [CommonHelper.PermissionList.ORG_ADMIN]
            },
            {
                value: 'Plan Info',
                icon: <BrandingWatermarkIcon sx={{ width: "20px", height: "20px" }} />,
                component: <PlanInfoComponent />,
                permissions: [CommonHelper.PermissionList.ORG_ADMIN]
            }
        ];
    }

    return (
        <>
            <Helmet>
                <title>Billing</title>
            </Helmet>
            <Box>
                <Tabs
                    value={currentTab}
                    scrollButtons="auto"
                    variant="scrollable"
                    sx={{ gap: '0.5rem', minHeight: '37px' }}
                    allowScrollButtonsMobile
                    onChange={(e, value) => setCurrentTab(value)}
                >
                    {ACCOUNT_TABS.map((tab) => {
                        let isShow = tab.permissions.some(b => userPermissions[b]);
                        return isShow && <Tab
                            disableRipple
                            key={tab.value}
                            label={isMobile ? "" : capitalCase(tab.value)}
                            icon={tab.icon}
                            value={tab.value}
                            sx={{ flexDirection: 'row', gap: '0.25rem', padding: 0, minHeight: '10px', mr: '0.75rem', fontWeight: 600 }}
                        />
                    })}
                </Tabs>
            </Box>
            <Box sx={{ mb: 2 }} />
            {ACCOUNT_TABS.map((tab) => {
                let isShow = tab.permissions.some(b => userPermissions[b]);
                const isMatched = tab.value === currentTab;
                return isShow && isMatched && <Box key={tab.value}>{tab.component}</Box>;
            })}
        </>
    );
}
export default BillingInfoComponent;